import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import AddSetting from '../../components/super/add-setting';
import SettingsTable from '../../components/super/settings';
import commonStore from '../../stores/commonStore';

function ContentData({ row }) {
    return (
        <>
            {row.subject2s && row.subject2s.length > 0 && (
                <>
                    <strong>Subjects ({row.subject2s.length}):</strong>
                    {row.subject2s.map((s) => (
                        <>
                            <Link to={`/content/subjects/${s.id}/edit`}>
                                {s.episode_number}
                            </Link>
                            {', '}
                        </>
                    ))}
                </>
            )}
            <br />
            {row.assessment_questions && row.assessment_questions.length > 0 && (
                <>
                    <strong>
                        Questions ({row.assessment_questions.length}):{' '}
                    </strong>
                    {row.assessment_questions.map((s) => (
                        <>
                            <Link to={`/content/questions/${s.id}/edit`}>
                                {s.name}
                            </Link>
                            {', '}
                        </>
                    ))}
                </>
            )}
        </>
    );
}

function ThreatArea() {
    const history = useHistory();
    const { threatAreasStore, commonStore } = useStores();
    let { id } = useParams();
    const {
        loadingThreatArea,
        editingArea,
        error,
        creatingThreatArea,
        updatingThreatAreas,
        creatingKb,
        removingKbs,
    } = threatAreasStore;

    const [nameError, setNameError] = useState(false);
    const [codeError, setCodeError] = useState(false);

    const match = useRouteMatch();

    const addKb = async (k, v) => {
        await threatAreasStore.addKb(id, k, v);
        await threatAreasStore.loadArea(id);
    };

    const removeKb = async (kbId) => {
        commonStore.showDeleteConfirm('Key Behaviour', kbId, async () => {
            await threatAreasStore.removeKb(id, kbId);
            await threatAreasStore.loadArea(id);
        });
    };

    const onChangeKbValue = async (kbId, value) => {
        await threatAreasStore.setKbValue(id, kbId, value);
        await threatAreasStore.debouncedModifyKb(id, kbId);
    };

    const onChangeKbKey = async (kbId, value) => {
        await threatAreasStore.setKbKey(id, kbId, value);
        await threatAreasStore.debouncedModifyKb(id, kbId);
    };

    const onBlurSettingValue = async (kbId, value) => {
        await threatAreasStore.setKbValue(id, kbId, value, true);
    }

    const onBlurSettingKey = async (kbId, value) => {
        await threatAreasStore.setKbKey(id, kbId, value, true);
    }

    const loadData = async () => {
        id
            ? await threatAreasStore.loadArea(id)
            : (threatAreasStore.editingArea = {
                  threatAreaCode: '',
                  threatAreaName: '',
                  kbs: [],
              });
    };

    useEffect(() => {
        loadData();
    }, [id]);

    const handleSave = async () => {
        if (!threatAreasStore.editingArea.threatAreaName) {
            setNameError(true);
            return;
        }
        if (!threatAreasStore.editingArea.threatAreaCode) {
            setCodeError(true);
            return;
        }
        setNameError(false);
        let result;
        id
            ? (result = await threatAreasStore.updateThreatArea(
                  threatAreasStore.editingArea.id,
                  {
                      ...threatAreasStore.editingArea,
                  }
              ))
            : (result = await threatAreasStore.createThreadArea(
                  threatAreasStore.editingArea
              ));
        if (result) {
            id
                ? history.push(`/content/threatareas/${id}/edit`)
                : history.push('/content/threatareas');
        }
    };

    return loadingThreatArea || !editingArea ? (
        <ThemedSpinner />
    ) : (
        editingArea && (
            <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
                align="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Box mt={4}>
                        <Typography variant="h2" gutterBottom>
                            ThreatArea: {editingArea.threatAreaName}
                        </Typography>
                    </Box>
                </Grid>

                {error && (
                    <Grid item xs={12}>
                        <ErrorMessage error={error} />
                    </Grid>
                )}
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="code"
                        value={editingArea.threatAreaCode || ''}
                        onChange={(e) => {
                            editingArea.threatAreaCode = e.target.value;
                        }}
                        error={nameError}
                        helperText={nameError ? 'Code is required' : ''}
                        variant="outlined"
                        label="Code"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextField
                        type="text"
                        name="name"
                        value={editingArea.threatAreaName || ''}
                        onChange={(e) => {
                            editingArea.threatAreaName = e.target.value;
                        }}
                        error={codeError}
                        helperText={codeError ? 'Name is required' : ''}
                        variant="outlined"
                        label="Name"
                        fullWidth
                    />
                </Grid>
                {error && (
                    <Grid item xs={12}>
                        <ErrorMessage error={error} />
                    </Grid>
                )}
                <Grid item xs={12}>
                    <Box mb={match.path.includes('edit') ? 0 : 4}>
                        {creatingThreatArea ||
                        (id && updatingThreatAreas.includes(id)) ? (
                            <ThemedSpinner />
                        ) : (
                            <Button
                                onClick={handleSave}
                                color="primary"
                                variant="contained"
                            >
                                Save
                            </Button>
                        )}
                    </Box>
                </Grid>

                {id && (
                    <>
                        {' '}
                        <Grid item xs={12}>
                            <AddSetting
                                nameField={'KeyBehaviour Code'}
                                valueField={'KeyBehaviour Name'}
                                saveSetting={addKb}
                                addingSetting={creatingKb}
                            ></AddSetting>
                        </Grid>
                        {editingArea.keyBehaviours &&
                            editingArea.keyBehaviours.length > 0 && (
                                <Grid item xs={10} md={6} style={{maxWidth: '100%', paddingTop: '25px'}}>
                                    <SettingsTable
                                        keyField={'kbCode'}
                                        valueField={'kbName'}
                                        editableKey={true}
                                        settings={editingArea.keyBehaviours}
                                        removeSetting={removeKb}
                                        removingSettings={removingKbs}
                                        onChangeSettingValue={onChangeKbValue}
                                        onChangeSettingKey={onChangeKbKey}
                                        onBlurSettingValue={onBlurSettingValue}
                                        onBlurSettingKey={onBlurSettingKey}
                                        additionalSection={(row) => (
                                            <ContentData row={row} />
                                        )}
                                    />
                                </Grid>
                            )}
                    </>
                )}
            </Grid>
        )
    );
}

export default observer(ThreatArea);
