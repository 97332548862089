import React, { useEffect, useState } from 'react';
import { observer, useLocalObservable } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import FormDef from '../../forms/template';
import ACLEditor from './ACLEditor';
import { Link, useParams, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SimpleTable from '../../components/super/SimpleTable';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { enforceSlash } from '../../utils/helpers';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '../../components/input/textField';
import MUITextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';

const possibleStandards = [
    'SOC2',
    'ISO27001',
    'HIPAA',
    'GDPR',
    'CCPA',
    'PCI',
    'NIS2',
];

function Template() {
    const { superStore } = useStores();
    let { templateId } = useParams();
    const history = useHistory();
    const isNew = templateId === 'create';
    const {
        loadingTemplate,
        editingTemplate,
        templatesError,
        editingTemplateSubjects,
        updatingTemplateStandards,
        templateStandardsError,
        updatingTemplateFlag,
        updatingTemplateError,
        updatingTemplate,
        creatingTemplate,
    } = superStore;

    const match = useRouteMatch();

    const loadData = async () => {
        if (!isNew) {
            await superStore.loadTemplate(templateId);
            await superStore.loadTemplateSubjects(templateId);
        } else {
            setEdit(true);
        }
    };

    const [edit, setEdit] = useState(false);

    const localStore = useLocalObservable(() => {
        let _form = new FormDef({
            async onSuccess(form) {
                if (isNew) {
                    const result = await superStore.createTemplate(
                        form.values()
                    );
                    setEdit(false);
                    history.push('/content/templates/' + result.id);
                } else {
                    const result = await superStore.saveTemplate(
                        templateId,
                        form.values()
                    );
                    if (result) {
                        setEdit(false);
                    }
                }
            },
            onError() {
                console.log('Error');
            },
        });

        return { _form };
    });

    useEffect(() => {
        if (editingTemplate) {
            setStandards(editingTemplate?.definition?.standards || []);
        }
    }, [editingTemplate]);

    useEffect(() => {
        loadData();
    }, [templateId]);

    const handleEdit = () => {
        localStore._form.update(editingTemplate);
        setEdit(true);
    };

    const handleSave = (e) => {
        localStore._form.onSubmit(e);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'subject_id',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.value}`}>{x.value}</Link>
                ),
            },
            {
                Header: 'Title',
                accessor: 'title',
                Cell: (x) => (
                    <Link to={`/content/subjects/${x.row.original.subject_id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Episode number',
                accessor: 'episode_number',
            },
        ],
        []
    );

    const [standards, setStandards] = React.useState([]);

    const handleSaveStandards = () => {
        superStore.saveStandards(templateId, { standards });
    };

    const handleChange = (event) => {
        if (standards.includes(event.target.value)) {
            setStandards(
                standards.filter((standard) => standard !== event.target.value)
            );
        } else setStandards([...standards, event.target.value]);
    };

    const handleChangePersistent = (event) => {
        if (updatingTemplateFlag) return;
        void superStore.saveFlag(
            templateId,
            'persistent',
            event.target.checked
        );
    };

    const handleChangeIsAddOn = (event) => {
        if (updatingTemplateFlag) return;
        void superStore.saveFlag(templateId, 'isAddOn', event.target.checked);
    };

    const handleChangeCanHaveAddOn = (event) => {
        if (updatingTemplateFlag) return;
        void superStore.saveFlag(
            templateId,
            'canHaveAddOn',
            event.target.checked
        );
    };

    return loadingTemplate || (!editingTemplate && !isNew) ? (
        <ThemedSpinner />
    ) : (
        (editingTemplate || isNew) && (
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                align="center"
                spacing={2}
            >
                {edit ? (
                    <>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                Component={MUITextField}
                                field={localStore._form.$('title')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                Component={MUITextField}
                                field={localStore._form.$('tip')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                Component={MUITextField}
                                field={localStore._form.$('description')}
                            ></TextField>
                            <TextField
                                fullWidth
                                variant="outlined"
                                Component={MUITextField}
                                field={localStore._form.$('frequency')}
                            ></TextField>
                        </Grid>
                        <Grid item xs={12}>
                            {updatingTemplateError && (
                                <ErrorMessage
                                    error={updatingTemplateError}
                                    errorType={'error'}
                                ></ErrorMessage>
                            )}
                            {(updatingTemplate || creatingTemplate) && (
                                <CircularProgress />
                            )}
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                onClick={handleSave}
                                disabled={updatingTemplate || creatingTemplate}
                            >
                                Save
                            </Button>
                            <Button
                                variant={'contained'}
                                color={'secondary'}
                                disabled={updatingTemplate || creatingTemplate}
                                onClick={() => setEdit(false)}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </>
                ) : (
                    <>
                        <Grid item xs={12}>
                            <Box mt={4}>
                                <Typography variant="h2" gutterBottom>
                                    Title: {editingTemplate?.title}
                                </Typography>
                                <Button
                                    variant={'contained'}
                                    color={'primary'}
                                    onClick={handleEdit}
                                >
                                    Edit
                                </Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Description:</strong>{' '}
                                {editingTemplate?.description}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                <strong>Tip:</strong> {editingTemplate?.tip}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" gutterBottom>
                                <strong>frequency:</strong>{' '}
                                {editingTemplate?.frequency}
                            </Typography>
                        </Grid>
                    </>
                )}

                {!isNew && (
                    <>
                        <Grid item xs={12}>
                            <ErrorMessage
                                errorType="error"
                                error={updatingTemplateError}
                            />
                            <Box mt={4}>
                                Persistent( Admins can add more subject but
                                cannot remove existing subjects):
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value={
                                                editingTemplate?.definition
                                                    ?.persistent
                                            }
                                            checked={
                                                !!editingTemplate?.definition
                                                    ?.persistent
                                            }
                                            onChange={handleChangePersistent}
                                            name={'persistent'}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                                {updatingTemplateFlag && <CircularProgress />}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box mt={4}>
                                AddOn( This Template can be selected as an
                                add-on to main template):
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={
                                                editingTemplate?.definition
                                                    ?.canHaveAddOn
                                            }
                                            value={
                                                editingTemplate?.definition
                                                    ?.isAddOn
                                            }
                                            checked={
                                                !!editingTemplate?.definition
                                                    ?.isAddOn
                                            }
                                            onChange={handleChangeIsAddOn}
                                            name={'isAddOn'}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                                {updatingTemplateFlag && <CircularProgress />}
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <Box mt={4}>
                                Can Have AddOn( When This Template selected,
                                addons will be suggested):
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            disabled={
                                                editingTemplate?.definition
                                                    ?.isAddOn
                                            }
                                            value={
                                                editingTemplate?.definition
                                                    ?.canHaveAddOn
                                            }
                                            checked={
                                                !!editingTemplate?.definition
                                                    ?.canHaveAddOn
                                            }
                                            onChange={handleChangeCanHaveAddOn}
                                            name={'canHaveAddOn'}
                                            color="primary"
                                        />
                                    }
                                    label=""
                                />
                                {updatingTemplateFlag && <CircularProgress />}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ErrorMessage
                                errorType="error"
                                error={templateStandardsError}
                            />
                            <Box mt={4}>
                                Standards:
                                {possibleStandards.map((s) => {
                                    return (
                                        <FormControlLabel
                                            key={s}
                                            control={
                                                <Checkbox
                                                    value={s}
                                                    checked={standards.includes(
                                                        s
                                                    )}
                                                    onChange={handleChange}
                                                    name={s}
                                                    color="primary"
                                                />
                                            }
                                            label={s}
                                        />
                                    );
                                })}
                                {updatingTemplateStandards.includes(
                                    templateId
                                ) ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={_.isEqual(
                                            standards,
                                            editingTemplate?.definition
                                                ?.standards || []
                                        )}
                                        onClick={handleSaveStandards}
                                    >
                                        Save
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h4">Subjects</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="primary"
                                href={`${enforceSlash(match.url)}subjects`}
                                startIcon={<EditIcon />}
                            >
                                Edit Subjects
                            </Button>
                        </Grid>
                        {editingTemplateSubjects &&
                        editingTemplateSubjects.length > 0 ? (
                            <Grid item xs={8}>
                                <SimpleTable
                                    columns={columns}
                                    data={editingTemplateSubjects}
                                    needsFilter={
                                        editingTemplateSubjects.length > 5
                                    }
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={8}>
                                <Typography>No subjects found :(</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Box mt={4}>
                                <Typography variant="h4" gutterBottom>
                                    Access control
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <ACLEditor
                                acl={editingTemplate.acl}
                                unique_id={editingTemplate.unique_id}
                            />
                        </Grid>
                    </>
                )}

                {templatesError && (
                    <Grid item xs={12}>
                        <ErrorMessage error={templatesError} />
                    </Grid>
                )}
            </Grid>
        )
    );
}

export default observer(Template);
