import RestService from './RestService';

export default class TemplatesService extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/templates`);
        } else super('/templates');
    }

    all() {
        return this.get(`${this.baseUrl}/all`);
    }

    superGet(id) {
        return this.get(`${this.baseUrl}/all/${id}`);
    }

    superGetSubjects(id) {
        return this.get(`${this.baseUrl}/all/${id}/subjects`);
    }

    addSubject(templateId, subjectId) {
        return this.post(`${this.baseUrl}/${templateId}/subjects`, {
            subjectId,
        });
    }

    deleteSubject(templateId, subjectId) {
        return this.del(`${this.baseUrl}/${templateId}/subjects/${subjectId}`);
    }

    updateStandards(templateId, standards) {
        return this.put(`${this.baseUrl}/${templateId}/standards`, standards);
    }

    updateFlag(templateId, name, value) {
        return this.put(`${this.baseUrl}/${templateId}/${name}`, {
            [name]: value,
        });
    }
}
