import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';

class ServiceTasksStore {
    constructor() {
        makeAutoObservable(this);
    }

    serviceTasks = [];
    taskTypes = [];
    loadingTasks = false;
    loadingServiceTasks = false;
    creatingTask = false;
    preparingReport = false;
    executingReport = false;
    abandoningReport = false;
    loadingFailedList = false;
    resetingReport = false;
    rerunReport = false;
    updatingTasks = [];
    failedList = [];
    error = null;
    editingServiceTask = null;
    editingTaskArguments = null;

    setError(error, type) {
        this.error = error;
    }

    setArguments(v) {
        this.editingTaskArguments = v;
    }

    runPrepare = generateCreateEntity(
        'serviceTasks',
        this,
        'preparingReport',
        async (id) => {
            return services.ServiceTasks.prepare(id);
        }
    );

    runExecute = generateCreateEntity(
        'serviceTasks',
        this,
        'executingReport',
        async (id) => {
            return services.ServiceTasks.execute(id);
        }
    );

    loadFailedList = generateLoadList(
        'serviceTasks',
        this,
        'loadingFailedList',
        async (id) => {
            return services.ServiceTasks.loadFailedList(id);
        },
        'failedList'
    );
    runAbandon = generateCreateEntity(
        'serviceTasks',
        this,
        'abandoningReport',
        async (id) => {
            return services.ServiceTasks.abandon(id);
        }
    );

    runReset = generateCreateEntity(
        'serviceTasks',
        this,
        'resetingReport',
        async (id) => {
            return services.ServiceTasks.reset(id);
        }
    );

    reRunFailed = generateCreateEntity(
        'serviceTasks',
        this,
        'rerunReport',
        async (id) => {
            return services.ServiceTasks.reRunFailed(id);
        }
    );

    loadServiceTasks = generateLoadList(
        'serviceTasks',
        this,
        'loadingTasks',
        async () => {
            return services.ServiceTasks.list();
        },
        'serviceTasks'
    );

    loadServiceTask = generateLoadEntity(
        'serviceTasks',
        this,
        'loadingServiceTasks',
        async (id) => {
            let q = await services.ServiceTasks.fetch(id);
            runInAction(() => {
                this.editingTaskArguments =
                    typeof q.arguments === 'string'
                        ? q.arguments
                        : JSON.stringify(q.arguments, null, 2);
            });
            return q;
        },
        'editingServiceTask'
    );

    loadingServiceTaskTypes = generateLoadEntity(
        'serviceTasksTypes',
        this,
        'loadingServiceTasksTypes',
        async () => {
            return await services.ServiceTasks.types();
        },
        'taskTypes'
    );

    updateTask = generateUpdateEntity(
        'serviceTasks',
        this,
        'updatingTasks',
        async (id, data) => {
            return services.ServiceTasks.update(data, id);
        }
    );
    createTask = generateCreateEntity(
        'serviceTasks',
        this,
        'creatingTask',
        async (data) => {
            return services.ServiceTasks.create(data);
        }
    );
}

export default ServiceTasksStore;
