import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import RemoveIcon from '@material-ui/icons/Remove';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import { Box } from '@mui/material';

function NumberInput({ value, onChange, min, max, step = 1, allowNull = false, modifyButtons = true, ...props }) {
    const [localValue, setLocalValue] = useState(value);
    const [prevValue, setPrevValue] = useState(value);
    const [isInvalid, setIsInvalid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (prevValue !== localValue && !isInvalid){
            onChange(localValue);
            setPrevValue(localValue);
        }
    }, [localValue]);

    const handleChange = (newValue) => {
        if (newValue === '' || newValue === null) {
            if (allowNull) {
                setLocalValue(null);
                setIsInvalid(false);
                setErrorMessage('');
            } else {
                setLocalValue(newValue);
                setIsInvalid(true);
                setErrorMessage('Value cannot be null');
            }
            return;
        }

        const numericValue = Number(newValue);
        const isNumeric = !isNaN(numericValue);

        if (isNumeric) {
            const isTooLow = min !== undefined && numericValue < min;
            const isTooHigh = max !== undefined && numericValue > max;

            if (!isTooLow && !isTooHigh) {
                setIsInvalid(false);
                setErrorMessage('');
            } else {
                setIsInvalid(true);
                setErrorMessage(
                    `Value must be${isTooLow ? ` ≥ ${min}` : ''}${isTooLow && isTooHigh ? ' and' : ''}${isTooHigh ? ` ≤ ${max}` : ''}`
                );
            }
            setLocalValue(numericValue);
        } else {
            setIsInvalid(true);
            setErrorMessage('Value must be a number');
        }
    };

    const increment = () => {
        const newValue = localValue + step;
        if (max !== undefined && newValue > max) {
            handleChange(max);
        } else if (min !== undefined && newValue < min) {
            handleChange(min);
        } else {
            handleChange(newValue);
        }
    };

    const decrement = () => {
        const newValue = localValue - step;
        if (min !== undefined && newValue < min) {
            handleChange(min);
        } else if (max !== undefined && newValue > max) {
            handleChange(max);
        } else {
            handleChange(newValue);
        }
    };

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
        >
            {modifyButtons && (
                <IconButton onClick={decrement} size="small">
                    <RemoveIcon />
                </IconButton>
            )}

            <Box position="relative" width="auto">
                <TextField
                    variant="outlined"
                    type="number"
                    value={String(localValue)}
                    onChange={(e) => handleChange(e.target.value)}
                    error={isInvalid}
                    InputProps={{
                        inputProps: { min, max, step },
                        style: {
                            textAlign: 'center',
                            width: '82px',
                            height: '40px',
                            padding: '4px',
                        },
                    }}
                    {...props}
                />
                {isInvalid && (
                    <Box
                        position="absolute"
                        bottom={-18}
                        left="50%"
                        style={{
                            transform: 'translateX(-50%)',
                            whiteSpace: 'nowrap',
                            fontSize: '0.7rem',
                            color: '#f44336',
                        }}
                    >
                        {errorMessage}
                    </Box>
                )}
            </Box>
            {modifyButtons && (
                <IconButton onClick={increment} size="small">
                    <AddIcon />
                </IconButton>
            )}
        </Box>
    );
}

export default observer(NumberInput);
