import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateLoadEntity,
    generateUpdateEntity,
} from '../utils/mobx';

class NotificationsStore {
    constructor() {
        makeAutoObservable(this);
    }

    status = null;
    loadingStatus = false;
    updatingStatus = [];
    loadingReason = false;
    updatingReason = false;
    reason = null;
    error = null;

    setError(error, type) {
        this.error = error;
    }

    loadReason = generateLoadEntity(
        'loadReason',
        this,
        'loadingReason',
        async (reasonId) => {
            return services.Notifications.fetch(reasonId);
        },
        'reason'
    );

    loadStatus = generateLoadEntity(
        'status',
        this,
        'loadingStatus',
        async () => {
            return services.Notifications.statusService().list();
        },
        'status'
    );

    updateStatus = generateUpdateEntity(
        'status',
        this,
        'updatingStatus',
        async ({ key, enabled }) => {
            return services.Notifications.statusService().update(
                { enabled },
                key
            );
        }
    );

    update = generateUpdateEntity(
        'reasonUpdate',
        this,
        'updatingReason',
        async (id, values) => {
            return services.Notifications.update(values, id);
        },
        (result) => {
            runInAction(() => {
                this.reason = result;
            });
        }
    );
}

export default NotificationsStore;
