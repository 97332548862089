import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateUpdateEntity,
} from '../utils/mobx';

class VideosStore {
    constructor() {
        makeAutoObservable(this);
    }

    schedule = null;
    loadingSchedule = false;
    runningSchedule = false;
    runningReminders = false;
    updatingEvents = [];
    cleaningNotifications = [];
    error = null;

    setError(error, type) {
        this.error = error;
    }

    updateEvent(index, values) {
        if (this.schedule) {
            if (!this.schedule.events[index].originalData)
                this.schedule.events[index].originalData = {
                    ...this.schedule.events[index],
                    dirty: false,
                };
            Object.assign(this.schedule.events[index], values);
        }
    }

    revertEvent(index) {
        if (this.schedule) {
            if (this.schedule.events[index].originalData)
                Object.assign(
                    this.schedule.events[index],
                    this.schedule.events[index].originalData
                );
        }
    }

    async saveChanges(index) {
        if (this.schedule) {
            if (this.schedule.events[index].dirty) {
                await this.updateScheduleEvent(
                    this.schedule.trainingId,
                    this.schedule.events[index]
                );
            }
        }
    }

    async cleanNotifications(index) {
        if (this.schedule) {
            if (this.schedule.events[index]) {
                const result = await this.doCleanNotifications({
                    eventId: this.schedule.events[index].id,
                    trainingId: this.schedule.trainingId,
                });
                return result;
            }
        }
    }

    doCleanNotifications = generateDeleteEntity(
        'schedule',
        this,
        'cleaningNotifications',
        async ({ eventId, trainingId }) => {
            return services.Automations.ScheduleService(
                trainingId
            ).cleanNotifications(eventId);
        },
        (data) => {
            return data.eventId;
        }
    );

    updateScheduleEvent = generateUpdateEntity(
        'schedule',
        this,
        'updatingEvents',
        async (trainingID, event) => {
            await services.Automations.ScheduleService(trainingID).update(
                {
                    status: event.status,
                    send: event.send,
                },
                event.id
            );
            runInAction(() => {
                event.dirty = false;
            });
        }
    );

    loadSchedule = generateLoadEntity(
        'schedule',
        this,
        'loadingSchedule',
        async (trainingID) => {
            return services.Automations.ScheduleService(trainingID).list();
        },
        'schedule'
    );

    runSchedule = generateCreateEntity(
        'schedule',
        this,
        'runningSchedule',
        async (trainingID) => {
            console.log(trainingID);
            return services.Automations.ScheduleService(
                trainingID
            ).runSchedule();
        }
    );

    runReminders = generateCreateEntity(
        'schedule',
        this,
        'runningReminders',
        async (trainingID) => {
            console.log(trainingID);
            return services.Automations.ScheduleService(
                trainingID
            ).runReminders();
        }
    );
}

export default VideosStore;
