import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleTable from '../../components/super/SimpleTable';
import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import NumberInput from '../../components/input/NumberInput';
import Button from '@material-ui/core/Button';
import { runInAction } from 'mobx';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function Templates() {
    const { superStore } = useStores();
    const classes = useStyles();
    const { loadingTemplates, templates, sortedTemplates, templatesError } =
        superStore;
    let match = useRouteMatch();

    const loadData = async () => {
        superStore.loadTemplates();
    };

    const reorderTemplates = () => {
        runInAction(() => {
            superStore.reorderingTemplates = true;
            superStore.templates = superStore.sortedTemplates
        });

        setTimeout(() => {
            runInAction(() => {
                superStore.reorderingTemplates = false;
            });
        }, 0);
    }

    const updateOrder = async (templateId, orderNumber) => {
        superStore.updateTemplateOrder(templateId, orderNumber);
        await superStore.debouncedSaveTemplateOrder(
            templateId,
            orderNumber
        );
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Native Name',
                accessor: 'title',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.row.original.id}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: '# of Rules',
                accessor: 'rulesCount',
            },
            {
                Header: 'Public',
                accessor: 'isPublic',
                Cell: (x) =>
                    x.value > 0 ? (
                        <DoneIcon color="primary" />
                    ) : (
                        <ClearIcon color="secondary" />
                    ),
            },
            {
                Header: '# of Subjects',
                accessor: 'subjects',
            },
            {
                Header: 'Order',
                accessor: 'order_number',
                textAlign: 'center',
                Cell: observer((x) => {
                    let value = superStore.templates.find(
                        (template) => template.id === x.row.original.id
                    ).order_number;

                    return (
                        <NumberInput
                            value={value}
                            onChange={(orderNumber) =>
                                updateOrder(x.row.original.id, orderNumber)
                            }
                            min={0}
                            step={1}
                        />
                    );
                }),
            },
        ],
        [match.url]
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Tooltip title="Create template" aria-label="add" placement="left">
                <Fab
                    color="primary"
                    href="/content/templates/create"
                    className={classes.fab}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Templates</Typography>
                </Box>
            </Grid>
            {loadingTemplates ? (
                <ThemedSpinner />
            ) : templates && templates.length > 0 ? (
                <Grid item xs={12}>
                    <SimpleTable
                        columns={columns}
                        data={sortedTemplates}
                        needsFilter={true}
                        extraAction={
                            <Button
                                onClick={reorderTemplates}
                                color="primary"
                                variant="contained"
                            >
                                Reorder
                            </Button>
                        }
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Typography>No templates found :(</Typography>
                </Grid>
            )}
            {templatesError && (
                <Grid item>
                    <ErrorMessage error={templatesError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Templates);
