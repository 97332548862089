import { makeAutoObservable } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';
import debounceFn from 'debounce-fn';

class TagsStore {
    constructor() {
        makeAutoObservable(this);
    }

    threatAreas = [];
    error = null;
    loadingThreatAreas = false;
    loadingThreatArea = false;
    editingArea = null;
    creatingThreatArea = false;
    creatingKb = false;
    removingKb = [];
    updatingKb = [];
    updatingThreatAreas = [];
    removingThreatAreas = [];
    removingKbs = [];

    setError(error, type) {
        this.error = error;
    }

    loadThreatAreas = generateLoadList(
        'threatAreas',
        this,
        'loadingThreatAreas',
        async () => {
            return services.ThreatAreas.list();
        },
        'threatAreas'
    );

    loadArea = generateLoadEntity(
        'threatArea',
        this,
        'loadingThreatArea',
        async (id) => {
            return services.ThreatAreas.fetch(id);
        },
        'editingArea'
    );

    createThreadArea = generateCreateEntity(
        'threatArea',
        this,
        'creatingThreatArea',
        async (data) => {
            return services.ThreatAreas.create({ ...data });
        }
    );

    updateThreatArea = generateUpdateEntity(
        'threatArea',
        this,
        'updatingThreatAreas',
        async (key, data) => {
            return services.ThreatAreas.update(data, data.id);
        }
    );
    removeThreatArea = generateUpdateEntity(
        'threatArea',
        this,
        'removingThreatAreas',
        async (key) => {
            return services.ThreatAreas.delete(key);
        }
    );

    addKb = generateCreateEntity(
        'kb',
        this,
        'creatingKb',
        async (taID, key, value) => {
            key = key.trim();
            value = value.trim();
            return services.ThreatAreas.KeyBehaviorService(taID).create({
                kbCode: key,
                kbName: value,
            });
        }
    );

    removeKb = generateDeleteEntity(
        'kb',
        this,
        'removingKb',
        async (taID, key) => {
            return services.ThreatAreas.KeyBehaviorService(taID).delete(key);
        }
    );

    modifyKb = generateUpdateEntity(
        'kb',
        this,
        'updatingKb',
        async (taID, key) => {
            const kb = this.editingArea.keyBehaviours.find(
                (kb) => kb.id === key
            );
            const name = kb.kbName.trim();
            const value = kb.kbCode.trim();
            return services.ThreatAreas.KeyBehaviorService(
                taID
            ).update(
                {
                    kbName: name,
                    kbCode: value
                },
                key
            );
        }
    );

    debouncedModifyKb = debounceFn(this.modifyKb, { wait: 500 });

    setKbValue = (taID, key, value, trim) => {
        const kb = this.editingArea.keyBehaviours.find((kb) => kb.id === key);
        if (kb) kb.kbName = trim ? value.trim() : value;
    };

    setKbKey = (taID, key, value, trim) => {
        const kb = this.editingArea.keyBehaviours.find((kb) => kb.id === key);
        if (kb) kb.kbCode = trim ? value.trim() : value;
    };
}

export default TagsStore;
