import RestService from './RestService';

export default class Settings extends RestService {
    constructor(parentService, parentId) {
        if (parentService && parentId) {
            super(`${parentService.baseUrl}/${parentId}/settings`);
        } else if (parentService) {
            super(`${parentService.baseUrl}/settings`);
        }
        else super('/settings');
    }
}
