import MobxReactForm from 'mobx-react-form';
import plugins from './plugins';

const fields = {
    title: {
        label: 'Title',
        rules: 'string|required',
        placeholder: 'Template name',
    },
    tip: {
        label: 'Tip',
        rules: 'string',
        placeholder: 'Template tip',
    },
    description: {
        label: 'Description',
        rules: 'string',
        placeholder: 'Template Description',
    },
    frequency: {
        label: 'Frequency',
        rules: 'string|required',
        placeholder: 'example: W3|M1',
    },
};

export default function create(hooks) {
    return new MobxReactForm({ fields }, { plugins, hooks });
}
