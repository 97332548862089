import { makeAutoObservable, runInAction } from 'mobx';
import services from '../services';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadList,
} from '../utils/mobx';

class TagsStore {
    constructor() {
        makeAutoObservable(this);
    }

    tags = [];
    removingTags = [];
    tagsError = null;
    loadingTags = false;
    creatingTag = false;

    setError(error, type) {
        this.error = error;
    }

    loadTags = generateLoadList(
        'tags',
        this,
        'loadingTags',
        async (targetId) => {
            return services.Tags.list({ targetId });
        },
        'tags'
    );

    addTag = generateCreateEntity('tags', this, 'creatingTag', async (data) => {
        return services.Tags.create(data);
    });

    removeTag = generateDeleteEntity(
        'tags',
        this,
        'removingTags',
        async (id) => {
            await services.Tags.delete(id);
            runInAction(() => {
                this.tags.replace(this.tags.filter((t) => t.id !== id));
            });
        }
    );
}

export default TagsStore;
