import React, { useEffect, useState } from 'react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import HtmlField from '../../components/input/HtmlField';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import { TextField } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Box } from '@mui/material';

const copySupport = { en: ['en-gb'], 'en-gb': ['en'] };

function SubjectLanguage({
    subject,
    question,
    content,
    language,
    editingSubjectLanguages,
    onRemove,
}) {
    const { superStore, commonStore } = useStores();
    const [translationTitle, setTranslationTitle] = useState('');
    const [translationDescription, setTranslationDescription] = useState('');
    const [videoIdjw, setvideoIdjw] = useState('');
    const [videoThumb, setVideoThumb] = useState('');
    const [questionText, setQuestionText] = useState('');
    const [questionAnswer, setQuestionAnswer] = useState('');
    const [questionWrong1, setQuestionWrong1] = useState('');
    const [questionWrong2, setQuestionWrong2] = useState('');
    const [questionWrong3, setQuestionWrong3] = useState('');
    const [translationEdited, setTranslationEdited] = useState(false);
    const [questionEdited, setQuestionEdited] = useState(false);
    const [contentEdited, setContentEdited] = useState(false);
    const [languageToCopy, setLanguageToCopy] = useState('');
    const [contentHtml, setContentHtml] = useState('');

    const { updatingSubjectLanguageContent, updatingSubjectLanguageQuestions, updatingSubjectLanguageTranslation } = superStore;
    
    useEffect(() => {
        resetValues();
    }, [subject, question]);

    const onRevertChanges = () => {
        commonStore.showConfirm(
            `Are you sure you want to revert all changes you have made?`,
            'Revert',
            'Revert changes',
            async () => {
                resetValues()
            }
        );
    };

    const resetValues = () => {
        setTranslationTitle(subject?.title || '');
        setTranslationDescription(subject?.description || '');
        setvideoIdjw(subject?.video_id_jw || '');
        setVideoThumb(subject?.video_thumb || '');
        setQuestionText(question?.question || '');
        setQuestionAnswer(question?.correct_answer || '');
        setQuestionWrong1(question?.wrong_answer1 || '');
        setQuestionWrong2(question?.wrong_answer2 || '');
        setQuestionWrong3(question?.wrong_answer3 || '');
        setContentHtml(content?.content || '<p><br></p>');
        setTranslationEdited(false);
        setQuestionEdited(false);
        setContentEdited(false);
    }
    const copyLanguage = () => {
        if (!languageToCopy) return;
        commonStore.showConfirm(
            `Are you sure you want to copy all content from ${languageToCopy} to ${language}?`,
            'Copy',
            'Copy language',
            async () => {
                const c = editingSubjectLanguages.contents[languageToCopy];
                const q = editingSubjectLanguages.questions[languageToCopy];
                const s = editingSubjectLanguages.languages[languageToCopy];

                if(!c && !q && !s) return;

                if(c && c.content){
                    setContentHtml(c.content);
                    setContentEdited(true);
                }

                setQuestionText(q && q.question? q.question : '');
                setQuestionAnswer(q && q.correct_answer ? q.correct_answer : '');
                setQuestionWrong1(q && q.wrong_answer1 ? q.wrong_answer1 : '');
                setQuestionWrong2(q && q.wrong_answer2 ? q.wrong_answer2 : '');
                setQuestionWrong3(q && q.wrong_answer3 ? q.wrong_answer3 : '');
                setQuestionEdited(true);

                setTranslationTitle(s && s.title ? s.title : '');
                setTranslationDescription(s && s.description ? s.description : '');
                setTranslationEdited(true);
            }
        );
    };

    const onChangeTranslationTitle = (e) => {
        setTranslationEdited(true);
        setTranslationTitle(e.target.value);
    };

    const onChangeTranslationDescription = (e) => {
        setTranslationEdited(true);
        setTranslationDescription(e.target.value);
    };

    const onChangeVideoJW = (e) => {
        setTranslationEdited(true);
        setvideoIdjw(e.target.value);
    };
    const onChangeVideoThumb = (e) => {
        setTranslationEdited(true);
        setVideoThumb(e.target.value);
    };

    const onChangeQuestionText = (e) => {
        setQuestionEdited(true);
        setQuestionText(e.target.value);
    };

    const onChangeQuestionAnswer = (e) => {
        setQuestionEdited(true);
        setQuestionAnswer(e.target.value);
    };

    const onChangeQuestionWrong1 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong1(e.target.value);
    };
    const onChangeQuestionWrong2 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong2(e.target.value);
    };
    const onChangeQuestionWrong3 = (e) => {
        setQuestionEdited(true);
        setQuestionWrong3(e.target.value);
    };

    const onChange = (value) => {
        setContentEdited(true);
        setContentHtml(value);
    };

    const onSave = async () => {
        if (translationEdited) {
            await superStore.editSubjectLanguageTranslation(
                subject.subject_id,
                subject.language,
                translationTitle,
                translationDescription,
                videoIdjw,
                videoThumb
            );
            setTranslationEdited(false);
        }

        if (questionEdited) {
            await superStore.editSubjectLanguageQuestions(
                subject.subject_id,
                subject.language,
                questionText,
                questionAnswer,
                questionWrong1,
                questionWrong2,
                questionWrong3
            );
            setQuestionEdited(false);
        }

        if (contentEdited) {
            await superStore.editSubjectLanguageContent(
                subject.subject_id,
                subject.language,
                contentHtml
            );
            setContentEdited(false);
        }
    };

    return !subject ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" width="100%">
                    <Box flexGrow={1} />

                    {copySupport[language] && (
                        <Box display="flex" alignItems="center" gap={2}>
                            <Typography>Copy from:</Typography>
                            <Select
                                value={languageToCopy}
                                onChange={(e) => setLanguageToCopy(e.target.value)}
                            >
                                {copySupport[language].map((l) => (
                                    <MenuItem key={l} value={l}>{l}</MenuItem>
                                ))}
                            </Select>
                            <Button size="small" variant="contained" color="primary" onClick={copyLanguage}>
                                Copy
                            </Button>
                        </Box>
                    )}

                    <Box flexGrow={1} display="flex" justifyContent="flex-end">
                        <Button variant="contained" color="secondary" onClick={onRevertChanges}>
                            Revert Changes
                        </Button>
                    </Box>
                </Box>
            </Grid>



            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="subjectTitle"
                    id="subjectTitle"
                    value={translationTitle}
                    onChange={onChangeTranslationTitle}
                    variant="outlined"
                    label="Title"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="textarea"
                    multiline
                    name="description"
                    id="description"
                    value={translationDescription}
                    onChange={onChangeTranslationDescription}
                    variant="outlined"
                    label="Description"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="textarea"
                    multiline
                    name="video id JW"
                    id="video_id_jw"
                    value={videoIdjw}
                    onChange={onChangeVideoJW}
                    variant="outlined"
                    label={<>JW Player id</>}
                    fullWidth
                />
                <a
                    rel={'noreferrer'}
                    href={
                        'https://dashboard.jwplayer.com/p/VhAfR9Qo/media/' +
                        videoIdjw
                    }
                    target={'_blank'}
                >
                    {' '}
                    open
                </a>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="question"
                    id="question"
                    value={videoThumb}
                    onChange={onChangeVideoThumb}
                    variant="outlined"
                    label="Thumbnail"
                    fullWidth
                />
            </Grid>

            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="question"
                    id="question"
                    value={questionText}
                    onChange={onChangeQuestionText}
                    variant="outlined"
                    label="Question"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="answer"
                    id="answer"
                    value={questionAnswer}
                    onChange={onChangeQuestionAnswer}
                    variant="outlined"
                    label="Answer"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong1"
                    id="wrong1"
                    value={questionWrong1}
                    onChange={onChangeQuestionWrong1}
                    variant="outlined"
                    label="Wrong 1"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong2"
                    id="wrong2"
                    value={questionWrong2}
                    onChange={onChangeQuestionWrong2}
                    variant="outlined"
                    label="Wrong 2"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    type="text"
                    name="wrong3"
                    id="wrong3"
                    value={questionWrong3}
                    onChange={onChangeQuestionWrong3}
                    variant="outlined"
                    label="Wrong 3"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1">Content</Typography>
                <HtmlField
                    initialValue={content?.content}
                    fieldValue={contentHtml}
                    onChange={onChange}
                />
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                    disabled={updatingSubjectLanguageContent.includes(
                        subject.subject_id
                    ) || updatingSubjectLanguageQuestions.includes(
                        subject.subject_id
                    ) || updatingSubjectLanguageTranslation.includes(
                        subject.subject_id
                    )}
                    startIcon={
                        (updatingSubjectLanguageContent.includes(
                            subject.subject_id
                        ) || updatingSubjectLanguageQuestions.includes(
                            subject.subject_id
                        ) || updatingSubjectLanguageTranslation.includes(
                            subject.subject_id)
                        ) && <CircularProgress size={20} />
                    }
                >
                    Save
                </Button>
                <Button onClick={onRemove} color="secondary" variant="contained">
                    Remove language
                </Button>
            </Grid>
        </Grid>
    );
}

export default observer(SubjectLanguage);
