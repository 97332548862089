import React, { Fragment, useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import moment from 'moment';
import { useStores } from '../../hooks/use-stores';
import SimpleTable from '../../components/super/SimpleTable';
import { TextField } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Alert } from 'reactstrap';
import { Grid } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { enforceSlash } from '../../utils/helpers';
import NumberInput from '../../components/input/NumberInput';
import { runInAction } from 'mobx';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function Languages() {
    const { superStore } = useStores();
    const {
        loadingLanguages,
        languages,
        sortedLanguagesDynamic: sortedlanguages,
        languageError,
    } = superStore;
    let match = useRouteMatch();
    const classes = useStyles();

    const loadData = async () => {
        superStore.loadLanguages();
    };

    const reorderLanguages = () => {
        runInAction(() => {
            superStore.reorderingLanguages = true;
            superStore.languages = superStore.sortedlanguages;
        });

        setTimeout(() => {
            runInAction(() => {
                superStore.reorderingLanguages = false;
            });
        }, 0);
    }
    const updateOrder = async (languageId, orderNumber) => {
        superStore.updateLanguageOrder(languageId, orderNumber);
        await superStore.debouncedSaveLanguageOrder(languageId, orderNumber);
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Code',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.row.original.id}`}>
                        {x.value}
                    </Link>
                ),
                accessor: 'code',
            },
            {
                Header: 'Native name',
                accessor: 'name',
            },
            {
                Header: 'English Name',
                accessor: 'english_name',
            },
            {
                Header: 'Version',
                accessor: 'version',
            },
            {
                Header: 'Icon',
                accessor: 'icon',
            },
            {
                Header: 'Flag',
                accessor: (x) => (
                    <Avatar alt="country flag" src={`${x.flag_image}`} />
                ),
            },
            {
                Header: 'Published',
                accessor: (x) =>
                    x.published ? (
                        <DoneIcon color="primary" fontSize="large" />
                    ) : (
                        <ClearIcon color="secondary" fontSize="large" />
                    ),
            },
            {
                Header: 'Resources',
                accessor: 'resourcesCount',
            },
            {
                Header: 'Last Uploaded',
                accessor: (x) => moment(x.lastImported).format('L'),
            },
            {
                Header: 'Last PO Edited',
                accessor: (x) =>
                    x.lastUpdatedInPOEditor &&
                    moment(x.lastUpdatedInPOEditor).format('L'),
            },
            {
                Header: 'Subjects',
                accessor: 'subjectsCount',
            },
            {
                Header: 'Notes',
                textAlign: 'center',
                accessor: (x) =>
                    x.note && <Alert color={x.noteType}>{x.note}</Alert>,
            },
            {
                Header: 'Order',
                accessor: 'order',
                textAlign: 'center',
                Cell: observer((x) => {
                    let value = superStore.languages.find(
                        (language) => language.id === x.row.original.id
                    ).order;

                    return (
                        <NumberInput
                            value={value}
                            onChange={(order) =>
                                updateOrder(x.row.original.id, order)
                            }
                            min={0}
                            step={1}
                        />
                    );
                }),
            },
        ],
        []
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={4}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Languages</Typography>
                </Box>
            </Grid>
            {loadingLanguages ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create language"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/languages/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>
                    {languageError && (
                        <Grid item xs={6}>
                            <ErrorMessage error={languageError} />
                        </Grid>
                    )}
                    {languages && languages.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={sortedlanguages}
                                needsFilter={true}
                                maxWith={1500}
                                extraAction={
                                    <Button
                                        onClick={reorderLanguages}
                                        color="primary"
                                        variant="contained"
                                    >
                                        Reorder
                                    </Button>
                                }
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(Languages);
