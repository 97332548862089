import { makeAutoObservable, observable, runInAction, toJS } from 'mobx';
import services from '../services';
import { extractErrorMessage, generatePerKeyDebounce } from '../utils/helpers';
import createCompanyEditForm from '../forms/company';
import createPartnerEditForm from '../forms/partner';
import createLanguageEditForm from '../forms/language';
import createPackageEditForm from '../forms/package';
import {
    generateCreateEntity,
    generateDeleteEntity,
    generateLoadEntity,
    generateLoadList,
    generateUpdateEntity,
} from '../utils/mobx';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import _each from 'lodash/each';
import { downloadFile } from '../utils/fileDownload';
import debounce from 'lodash/debounce';

class SuperStore {
    constructor(commonStore) {
        makeAutoObservable(this);
        this.commonStore = commonStore;
    }

    // General
    managerTokens = observable([]);
    mailHooks = observable([]);
    metricDates = observable([]);
    error = null;
    sendingTestEmail = false;
    togglingPartner = false;
    loadingMailHooks = false;

    // Partners
    partners = observable([]);
    currentPartner = null;
    editingPartner = null;
    loadingPartner = false;
    savingPartner = false;
    addingPartnerManager = false;
    removingPartnerManagers = observable([]);
    uploadingPartnerLogo = false;
    uploadingPartnerFavicon = false;
    uploadingLogoAsPartner = false;
    savingPartnerSettings = observable([]);
    addingPartnerSettings = false;
    loadingPartners = false;
    removingPartnerSettings = [];

    // Companies
    companies = observable([]);
    company = null;
    loadingCompanies = false;
    editingCompany = null;
    loadingCompany = false;
    savingCompany = false;
    addingCompanyManager = false;
    removingCompanyManagers = [];
    addingCompanySetting = false;
    removingCompanySettings = [];
    removingCompanies = observable([]);
    savingCompanySettings = observable([]);

    // Packages
    updatingPackages = observable([]);
    editingPackage = null;
    loadingPackage = false;
    addingPackage = false;
    removingPackages = observable([]);

    // Freemiums
    freemiums = observable([]);
    addingFreemium = false;
    updatingFreemiums = observable([]);
    removingFreemiums = observable([]);

    // Subscriptions
    subscriptions = observable([]);
    addingSubscription = false;
    updatingSubscriptions = observable([]);
    loadingPartnerSubscriptions = false;
    partnerSubscription = null;

    // ACL
    aclGroups = observable([]);
    aclRulesForPrincipal = observable([]);
    loadingAclRulesForPrincipal = false;
    creatingAclLine = false;
    removingAclLines = [];
    aclError = null;

    // Languages
    languages = [];
    updatingLanguages = [];
    reorderingLanguages = false;
    savingLanguage = false;
    loadingLanguage = false;
    loadingLanguages = false;
    creatingLanguage = false;
    editingLanguage = null;
    languageError = null;

    // Subjects
    loadingSubjects = false;
    exportingSubject = false;
    uploadingSubjects = false;
    loadingSubject = false;
    savingSubject = false;
    updatingSubjects = [];
    deletingSubjectLanguage = [];
    subjectLanguageError = null;
    creatingSubjectLanguage = false;
    editingSubject = null;
    editingSubjectLanguages = null;
    loadingSubjectCategories = false;
    editingSubjectCategories = observable([]);
    loadingSubjectTemplates = false;
    editingSubjectTemplates = observable([]);
    updatingSubjectLanguageContent = [];
    updatingSubjectLanguageTranslation = [];
    updatingSubjectLanguageQuestions = [];
    subjectsError = null;
    subjectsUploadError = null;

    // Questions
    questions = [];
    updatingQuestions = [];
    reorderingQuestions = false;
    removingQuestions = [];
    editingQuestion = null;
    editingQuestionDefinition = null;
    editingQuestionDescription = null;
    loadingQuestions = false;
    loadingQuestion = false;
    creatingQuestion = false;
    updatingQuestionsMaxScore = false;
    questionsMaxScoreUpdated = false;

    // Templates
    templates = [];
    loadingTemplates = false;
    loadingTemplate = false;
    editingTemplate = null;
    reorderingTemplates = false;
    updatingTemplate = false;
    updatingTemplates = [];
    updatingTemplateFlag = false;
    updatingTemplateStandards = [];
    updatingTemplateError = null;
    templateStandardsError = null;
    loadingTemplateSubjects = false;
    editingTemplateSubjects = [];
    templatesError = null;

    // Materials
    materials = [];
    creatingMaterial = null;
    editingMaterial = null;
    updatingMaterials = [];
    reorderingMaterials = false;
    loadingMaterials = false;
    loadingMaterial = false;
    removingMaterials = [];
    materialsError = null;

    // Categories
    categories = [];
    updatingCategories = [];
    reorderingCategories = false;
    loadingCategories = false;
    loadingCategory = false;
    editingCategory = null;
    loadingCategorySubjects = false;
    editingCategorySubjects = observable([]);
    categoriesError = null;

    // Admins
    admins = [];
    loadingAdmins = false;
    removingAdmins = [];
    addingSuperAdmin = false;
    adminsError = null;

    // PPV
    ppvLog = observable([]);
    ppvTransactions = observable([]);
    savingPPVApiKey = false;
    collectingPayment = false;
    loadingPPVLog = false;
    loadingPPVTransactions = false;
    savingPPV = false;

    // Trial Plan
    trialPlan = null;
    trialPlanError = null;
    readingTrialPlan = false;
    updatingTrialPlan = false;

    // Cache
    invalidatingCache = false;
    readCache = false;
    cacheData = null;

    // Edit Forms
    companyEditForm = null;
    partnerEditForm = null;
    languageEditForm = null;
    packageEditForm = null;

    // Other Updating Flags
    updatingTrainingStandards = [];
    trainingStandardsError = null;

    // System settings
    systemSettings = [];
    loadingSystemSettings = false;
    addingSystemSetting = false;
    removingSystemSettings = [];
    savingSystemSettings = [];

    setError(error, name) {
        switch (name) {
            case 'acl':
                this.aclError = error;
                break;

            case 'templates':
                this.templatesError = error;
                break;
            case 'templateUpdatingFlag':
                this.updatingTemplateError = error;
                break;

            case 'updatingTemplate':
                this.updatingTemplateError = error;
                break;
            case 'admins':
                this.adminsError = error;
                break;
            case 'categories':
                this.categoriesError = error;
                break;
            case 'subjects':
                this.subjectsError = error;
                break;
            case 'subjectsUpload':
                this.subjectsUploadError = error;
                break;
            case 'languages':
                this.languageError = error;
                break;
            case 'trialPlan':
                this.trialPlanError = error;
                break;
            case 'materials':
                this.materialsError = error;
                break;
            case 'templateStandards':
                this.templateStandardsError = error;
                break;
            case 'trainingStandards':
                this.trainingStandardsError = error;
                break;
            case 'subjectLanguage':
                this.subjectLanguageError = error;
                break;
            default:
                this.error = error;
        }
    }

    setTrialPlan(value) {
        this.trialPlan = value;
    }

    readTrialPlan = generateLoadEntity(
        'trialPlan',
        this,
        'readingTrialPlan',
        async () => {
            return services.System.trialPlanService().fetch();
        },
        'trialPlan'
    );

    updateTrialPlan = generateUpdateEntity(
        'trialPlan',
        this,
        'updatingTrialPlan',
        async (planId) => {
            return services.System.trialPlanService().update({ planId });
        }
    );

    makeQuestionCopy = generateUpdateEntity(
        'questionCopy',
        this,
        'questionCopy',
        async (questionId) => {
            return services.Questions.makeCopy(questionId);
        }
    );

    makeSubjectCopy = generateUpdateEntity(
        'subjectCopy',
        this,
        'subjectCopy',
        async (subjectId) => {
            return services.Subjects.makeCopy(subjectId);
        }
    );

    setDefinition(v) {
        this.editingQuestionDefinition = v;
    }
    setDescription(v) {
        this.editingQuestionDescription = v;
    }

    startEditLanguage(languageId, onSave) {
        this.languageEditForm = createLanguageEditForm({
            onSuccess: async (form) => {
                //alert("Form is valid! Send the request here.");
                // get field values
                let result = await this.updateLanguage(
                    languageId,
                    form.values()
                );
                if (onSave) onSave(result);
            },
            onError: (form) => {
                //alert("Form has errors!");
                // get all form errors
                console.log('All form errors', form.errors());
            },
        });
    }

    startEditCompany(companyId, onSave) {
        this.companyEditForm = createCompanyEditForm({
            onSuccess: async (form) => {
                //alert("Form is valid! Send the request here.");
                // get field values
                let result = await this.update(companyId, form.values());
                if (onSave) onSave(result);
            },
            onError: (form) => {
                //alert("Form has errors!");
                // get all form errors
                console.log('All form errors', form.errors());
            },
        });
    }

    startEditPackage(companyId, packageId, onSave) {
        this.packageEditForm = createPackageEditForm({
            onSuccess: async (form) => {
                //alert("Form is valid! Send the request here.");
                // get field values
                await this.updatePackage(companyId, packageId, form.values());
                if (onSave) onSave(companyId);
            },
            onError: (form) => {
                //alert("Form has errors!");
                // get all form errors
                console.log('All form errors', form.errors());
            },
        });
    }

    async update(companyId, values) {
        this.savingCompany = true;
        this.error = null;
        try {
            if (companyId) {
                let result = await services.Companies.update({
                    id: companyId,
                    ...values,
                });
                Object.assign(this.editingCompany, result, values);
                return companyId;
            } else {
                let responseCompanyId = await services.Companies.super().create(
                    { ...values },
                    true
                );
                await this.loadCompanies();
                return responseCompanyId;
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingCompany = false;
        }
    }

    async updateLanguage(languageId, values) {
        this.savingLanguage = true;
        this.error = null;
        try {
            if (languageId) {
                let result = await services.Languages.update({
                    id: languageId,
                    ...values,
                });
                if (this.editingLanguage)
                    Object.assign(this.editingLanguage, result, values);
                let lang = this.languages.find((x) => x.id === languageId);
                if (lang) Object.assign(lang, result, values);
            } else {
                let language = await services.Languages.create(
                    { ...values },
                    true
                );
                await this.loadLanguages();
                return language;
            }
            return true;
        } catch (e) {
            this.languageError = extractErrorMessage(e);
        } finally {
            this.savingLanguage = false;
        }
    }

    async updatePartner(partnerId, values) {
        this.savingPartner = true;
        this.error = null;
        try {
            if (partnerId) {
                let result = await services.Partners.update({
                    id: partnerId,
                    ...values,
                });
                Object.assign(this.editingPartner, result, values);
            } else {
                let partner = await services.Partners.create(
                    { ...values },
                    true
                );
                await this.loadPartners();
                return partner;
            }

            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPartner = false;
        }
    }

    clearEditingCompany() {
        this.editingCompany = null;
    }

    clearEditingPartner() {
        this.editingPartner = null;
    }

    async loadCompany(companyId) {
        if (this.loadingCompany) return;
        this.error = null;
        if (this.editingCompany != null) {
            if (this.editingCompany.id === companyId) return;
            else this.editingCompany = null;
        }
        try {
            this.loadingCompany = true;
            let editCompany = await services.Companies.fetch(companyId);
            runInAction(() => {
                this.editingCompany = editCompany;
                if (this.companyEditForm)
                    this.companyEditForm.update(
                        this.editingCompany
                            ? {
                                  name: this.editingCompany.company_name,
                              }
                            : {}
                    );
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.loadingCompany = false;
            });
        }
    }

    async loadPackage(packageId) {
        if (this.loadingPackage) return;
        this.error = null;
        if (this.editingPackage != null) {
            if (this.editingPackage.id === packageId) return;
            else this.editingPackage = null;
        }
        try {
            this.loadingPackage = true;
            const packages = toJS(this.editingCompany.packages);
            const editPackage = packages.find(
                (p) => p.package_id === parseInt(packageId)
            );
            runInAction(() => {
                this.editingPackage = editPackage;
                if (this.packageEditForm)
                    this.packageEditForm.update(
                        this.editingPackage ? editPackage : {}
                    );
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.loadingPackage = false;
            });
        }
    }

    async loadPartner(partnerId) {
        if (this.loadingPartner) return;
        this.error = null;
        if (this.editingPartner != null) {
            if (this.editingPartner.id === partnerId) return;
            else this.editingPartner = null;
        }
        try {
            this.loadingPartner = true;
            let editPartner = await services.Partners.fetch(partnerId);
            runInAction(() => {
                this.editingPartner = editPartner;
                if (this.partnerEditForm)
                    this.partnerEditForm.update(
                        this.editingPartner ? editPartner : {}
                    );
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.loadingPartner = false;
            });
        }
    }

    async addPackage(companyId, data) {
        this.addingPackage = true;
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).create(data);
            let editCompany = await services.Companies.fetch(companyId);
            runInAction(() => {
                this.editingCompany = editCompany;
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.addingPackage = false;
            });
        }
    }

    async removePackage(companyId, packageId) {
        this.removingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).delete(
                packageId
            );

            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            runInAction(() => {
                this.removingPackages.remove(packageId);
            });
        }
    }

    async updatePackage(companyId, packageId, values) {
        this.updatingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).update(
                values,
                packageId
            );
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingPackages.remove(packageId);
        }
    }

    async updatePackageVideos(companyId, packageId, videos) {
        this.updatingPackages.push(packageId);
        this.error = null;
        try {
            await services.Companies.packagesService(companyId).updateVideos(
                packageId,
                videos
            );
            this.editingCompany = await services.Companies.fetch(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingPackages.remove(packageId);
        }
    }

    async listFreemiums() {
        try {
            let result = await services.Freemiums.list();
            runInAction(() => {
                this.freemiums.replace(result);
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        }
    }

    async addFreemium(code, validity, limit, users) {
        this.addingFreemium = true;
        this.error = null;
        try {
            await services.Freemiums.create({ code, validity, limit, users });
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingFreemium = false;
        }
    }

    async updateFreemiumVideos(code, videos) {
        this.updatingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.updateVideos(code, videos);
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingFreemiums.remove(code);
        }
    }

    async removeFreemium(code) {
        this.removingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.delete(code);
            let result = await this.listFreemiums();
            runInAction(() => {
                return result;
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            this.removingFreemiums.remove(code);
        }
    }

    async resetCounter(code) {
        this.removingFreemiums.push(code);
        this.error = null;
        try {
            await services.Freemiums.resetCounter(code);
            await this.listFreemiums();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingFreemiums.remove(code);
        }
    }

    async listSubscriptions(companyId) {
        try {
            let result = await services.Companies.subscriptionsService(
                companyId
            ).listAll();
            runInAction(() => {
                this.subscriptions.replace(result);
            });
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        }
    }

    async addSubscription(
        code,
        type,
        trainingSubjects,
        assessmentQuestions,
        integrations,
        maxUsers
    ) {
        this.addingSubscription = true;
        this.error = null;
        try {
            await services.Subscriptions.createSubscriptionPlan({
                code,
                type,
                trainingSubjects,
                assessmentQuestions,
                integrations,
                maxUsers,
            });
            await this.listSubscriptions();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingSubscription = false;
        }
    }

    async updateSubscription(code, active) {
        this.updatingSubscriptions.push(code);
        this.error = null;
        try {
            await services.Subscriptions.updateSubscriptionPlan(code, active);
            await this.listSubscriptions();
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.updatingSubscriptions.remove(code);
        }
    }

    async addManager(companyId, data) {
        this.addingCompanyManager = true;
        this.error = null;
        try {
            await services.Companies.managersService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingCompanyManager = false;
        }
    }

    async setBillingPerson(partnerId, data) {
        this.addingPartnerManager = true;
        this.error = null;
        try {
            await services.Partners.managersService(
                partnerId
            ).setBillingManager(data);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPartnerManager = false;
        }
    }

    async addPartnerManager(partnerId, data) {
        this.addingPartnerManager = true;
        this.error = null;
        try {
            await services.Partners.managersService(partnerId).create(data);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.addingPartnerManager = false;
        }
    }

    removeCompanyManager = generateDeleteEntity(
        'companyManager',
        this,
        'removingCompanyManagers',
        async (email, companyId) => {
            await services.Companies.managersService(companyId).delete(email);
            this.editingCompany = await services.Companies.fetch(companyId);
        })

    removePartnerManager = generateDeleteEntity(
        'partnerManager',
        this,
        'removingPartnerManagers',
        async (userId, partnerId) => {
            await services.Partners.managersService(partnerId).delete(userId);
            this.editingPartner = await services.Partners.fetch(partnerId);
        })

    async impersonate(email, companyId) {
        try {
            let access_link = await services.System.impersonate(
                email,
                companyId
            );
            this.removeToken(email);
            this.managerTokens.push({ email, access_link });
            return access_link;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async impersonatePartner(email, partnerId) {
        try {
            let access_link = await services.System.impersonatePartner(
                email,
                partnerId
            );
            this.removeToken(email);
            this.managerTokens.push({ email, access_link });
            return access_link;
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    removeToken(email) {
        let existing = this.managerTokens.find((x) => x.email === email);
        if (existing) this.managerTokens.remove(existing);
    }

    get managerTokensIndex() {
        return keyBy(this.managerTokens, 'email');
    }

    addPartnerSetting = generateCreateEntity(
        'partnerSetting',
        this,
        'addingPartnerSettings',
        async (partnerId, data) => {
            data.value = data.value.trim();
            data.name = data.name.trim();
            await services.Partners.settingsService(partnerId).create(data);
            this.editingPartner = await services.Partners.fetch(partnerId);
        }
    );
    trimPartnerSetting(id, value) {
        value = value.trim();
        let s = this.editingPartner.settings.find((x) => x.id === id);
        s.value = value;
    }

    updatePartnerSetting(id, value) {
        let s = this.editingPartner.settings.find((x) => x.id === id);
        s.value = value;
    }

    savePartnerSetting = generateUpdateEntity(
        'partnerSetting',
        this,
        'savingPartnerSettings',
        async (key, partnerId) => {
            const setting = this.editingPartner.settings.find(
                (setting) => setting.id === key
            );
            const value = setting.value.trim();
            await services.Partners.settingsService(partnerId).update(
                { value: value },
                key
            );
        }
    );

    debouncedSavePartnerSetting = debounce(this.savePartnerSetting, 500);

    removePartnerSetting = generateDeleteEntity(
        'partnerSetting',
        this,
        'removingPartnerSettings',
        async (id, partnerId) => {
            await services.Partners.settingsService(partnerId).delete(id);
            this.editingPartner = await services.Partners.fetch(partnerId);
        },
        (data) => {
            return data.id;
        }
    );

    //Sends test/preview of email template, with the settings of the provided companyId
    //data: {email,type} - 'email' receives the given 'type' of email template
    async sendCompanyTestEmail(companyId, data) {
        this.sendingTestEmail = true;
        this.error = null;
        try {
            await services.Companies.sendTestEmail(companyId, data);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
            return false;
        } finally {
            this.sendingTestEmail = false;
        }
    }

    // ----- COMPANY SETTINGS

    addCompanySetting = generateCreateEntity(
        'companySetting',
        this,
        'addingCompanySetting',
        async (companyId, data) => {
            data.value = data.value.trim();
            data.name = data.name.trim();
            await services.Companies.settingsService(companyId).create(data);
            this.editingCompany = await services.Companies.fetch(companyId);
        }
    );

    trimCompanySetting(id, value) {
        value = value.trim();
        let s = this.editingCompany.settings.find((x) => x.id === id);
        s.value = value;
    }

    updateCompanySetting(id, value) {
        let s = this.editingCompany.settings.find((x) => x.id === id);
        s.value = value;
    }

    saveCompanySetting = generateUpdateEntity(
        'companySetting',
        this,
        'savingCompanySettings',
        async (key, companyId) => {
            const setting = this.editingCompany.settings.find(
                (setting) => setting.id === key
            );
            const value = setting.value.trim();
            await services.Companies.settingsService(companyId).update(
                { value: value },
                key
            );
        }
    );

    debouncedSaveCompanySetting = debounce(this.saveCompanySetting, 500);

    removeCompanySetting = generateDeleteEntity(
        'companySetting',
        this,
        'removingCompanySettings',
        async (key, companyId) => {
            await services.Companies.settingsService(companyId).delete(key);
            this.editingCompany = await services.Companies.fetch(companyId);
        },
    );

    //Fetch all Access Control Groups currently in the system
    async loadAclGroups() {
        this.error = null;
        try {
            this.aclGroups = await services.ACL.getAclGroups();
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async loadAclRulesForPrincipal(principal) {
        this.error = null;
        this.loadingAclRulesForPrincipal = true;
        try {
            this.aclRulesForPrincipal =
                await services.ACL.findAclRulesForPrincipal(principal);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingAclRulesForPrincipal = false;
        }
    }

    async togglePartner(partnerId, enabled) {
        this.error = null;
        this.togglingPartner = true;
        try {
            await services.Partners.togglePartner(partnerId, enabled);
            let result = await services.Partners.fetch(partnerId);
            runInAction(() => (this.editingPartner = result));
        } catch (e) {
            runInAction(() => {
                this.error = extractErrorMessage(e);
            });
        } finally {
            this.togglingPartner = false;
        }
    }

    async createPPV(companyId) {
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.createPPV(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    async addViews(companyId, views, notes) {
        if (!views) return;
        this.savingPPV = true;
        this.error = null;
        try {
            let ppv = await services.Companies.addViews(companyId, {
                views,
                notes,
            });
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPV = false;
        }
    }

    async loadPPVLog(companyId) {
        this.loadingPPVLog = true;
        this.error = null;
        try {
            let ppvLog = await services.Companies.ppvLog(companyId);
            this.ppvLog.replace(ppvLog);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVLog = false;
        }
    }

    async loadPPVTransactions(companyId) {
        this.loadingPPVTransactions = true;
        this.error = null;
        try {
            let ppvTransactions = await services.Companies.ppvTransactions(
                companyId
            );
            this.ppvTransactions.replace(ppvTransactions);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingPPVTransactions = false;
        }
    }

    async newPPVApiKey(companyId) {
        this.savingPPVApiKey = true;
        this.error = null;
        try {
            let ppv = await services.Companies.newPPVApiKey(companyId);
            this.editingCompany.ppv_package = ppv;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.savingPPVApiKey = false;
        }
    }

    async uploadLogoAsPartnerAdmin(companyId, formData) {
        this.uploadingLogoAsPartner = true;
        try {
            let result = await services.Resources.addLogo(companyId, formData);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, {
                    logo_name: result.logo_name,
                });
            }
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingLogoAsPartner = false;
        }
    }

    async uploadPartnerLogo(partnerId, formData) {
        this.uploadingPartnerLogo = true;
        try {
            await services.Partners.addLogo(partnerId, formData);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingPartnerLogo = false;
        }
    }

    async uploadPartnerFavicon(partnerId, formData) {
        this.uploadingPartnerFavicon = true;
        try {
            await services.Partners.addFavicon(partnerId, formData);
            this.editingPartner = await services.Partners.fetch(partnerId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.uploadingPartnerFavicon = false;
        }
    }

    async removeLogoAsPartnerAdmin(companyId) {
        try {
            let result = await services.Resources.removeLogo(companyId);
            this.editingCompany = await services.Companies.fetch(companyId);
            if (this.editingCompany) {
                Object.assign(this.editingCompany, result, { logo_name: null });
            }
            //await services.Resources.removeLogo(companyId);
        } catch (e) {
            this.error = extractErrorMessage(e);
        }
    }

    async loadCompanies() {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Companies.list();
            runInAction(() => {
                this.companies.replace(result);
            });
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            runInAction(() => {
                this.loadingCompanies = false;
            });
        }
    }

    async deleteCompany(partnerId, companyId) {
        this.removingCompanies.push(companyId);
        this.error = null;
        try {
            await services.Partners.companiesService(partnerId).delete(
                companyId
            );
            let existing = this.companies.find((x) => x.id === companyId);
            if (existing) this.companies.remove(existing);
            return true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.removingCompanies.remove(companyId);
        }
    }

    async loadPartnerCompanies(partnerId) {
        this.loadingCompanies = true;
        this.error = null;
        this.companies.clear();
        try {
            const result = await services.Partners.companiesService(
                partnerId
            ).list();
            this.companies.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingCompanies = false;
        }
    }

    startEditPartner(partnerId, onSave) {
        this.partnerEditForm = createPartnerEditForm({
            onSuccess: async (form) => {
                //alert("Form is valid! Send the request here.");
                // get field values
                let result = await this.updatePartner(partnerId, form.values());
                if (onSave) onSave(result);
            },
            onError: (form) => {
                //alert("Form has errors!");
                // get all form errors
                console.log('All form errors', form.errors());
            },
        });
    }

    async collectPartnerPayment(partnerId) {
        this.collectingPayment = true;
        this.error = null;
        try {
            await services.Partners.subscriptionsService(partnerId).collect();
            if (this.partnerSubscription)
                this.partnerSubscription.subscription.collected = true;
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.collectingPayment = false;
        }
    }

    loadPartners = generateLoadList(
        'partners',
        this,
        'loadingPartners',
        async (options) => {
            const result = await services.Partners.list(options);
            this.metricDates.replace(result.dates);
            return result.rows;
        },
        'partners'
    );

    loadPartnerSubscription = generateLoadEntity(
        'partnerSubscription',
        this,
        'loadingPartnerSubscriptions',
        async (partnerId) => {
            return services.Partners.subscriptionsService(partnerId).list();
        },
        'partnerSubscription'
    );

    async loadMailHooks() {
        this.loadingMailHooks = true;
        this.error = null;
        this.mailHooks.clear();
        try {
            const result = await services.System.listHooks();
            this.mailHooks.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingMailHooks = false;
        }
    }

    async createMailHooks() {
        this.loadingMailHooks = true;
        this.error = null;
        this.mailHooks.clear();
        try {
            const result = await services.System.createHooks();
            this.mailHooks.replace(result);
        } catch (e) {
            this.error = extractErrorMessage(e);
        } finally {
            this.loadingMailHooks = false;
        }
    }

    async openManagePartnerSession(partnerId) {
        return services.Partners.openManageSession(partnerId);
    }

    updateQuestionsMaxScore = generateLoadEntity(
        'questionsMaxScore',
        this,
        'updatingQuestionsMaxScore',
        async () => {
            this.questionsMaxScoreUpdated = false;
            return services.Questions.recalculateQuestionsMaxScore();
        },
        'questionsMaxScoreUpdated'
    );

    loadQuestions = generateLoadList(
        'questions',
        this,
        'loadingQuestions',
        async (options) => {
            return services.Questions.list(options);
        },
        'questions'
    );

    loadQuestion = generateLoadEntity(
        'questions',
        this,
        'loadingQuestion',
        async (id) => {
            let q = await services.Questions.fetch(id);
            runInAction(() => {
                this.editingQuestionDefinition =
                    typeof q.definition === 'string'
                        ? q.definition
                        : JSON.stringify(q.definition, null, 2);
            });
            return q;
        },
        'editingQuestion'
    );

    createQuestion = generateCreateEntity(
        'question',
        this,
        'creatingQuestion',
        async (question) => {
            return services.Questions.create(question);
        }
    );

    updateQuestion = generateUpdateEntity(
        'question',
        this,
        'updatingQuestions',
        async (key, question) => {
            return services.Questions.update(question, question.id);
        }
    );

    get sortedQuestions() {
        return this.reorderingQuestions
            ? orderBy(this.questions, 'order_number')
            : this.questions;
    }

    updateQuestionOrder(questionId, value) {
        const question =
            this.questions &&
            this.questions.find((x) => x.id === questionId);
        question.order_number = value;
    }

    saveQuestionOrder = generateUpdateEntity(
        'updatingQuestions',
        this,
        'updatingQuestions',
        async (questionId, order_number) => {
            console.log('saveQuestionOrder', questionId, order_number);
            await services.Questions.update(
                { order_number },
                questionId
            );

            return true;
        }
    );

    debouncedSaveQuestionOrder = generatePerKeyDebounce(
        async (questionId, order_number) => {
            await this.saveQuestionOrder(questionId, order_number);
        },
        300
    );


    invalidateCacheKey = generateCreateEntity(
        'invalidateCacheKey',
        this,
        'invalidatingCache',
        async (key) => {
            return services.System.invalidateCache(key);
        }
    );
    invalidateSystemSettings = generateCreateEntity(
        'invalidateCacheKey',
        this,
        'invalidatingCache',
        async () => {
            return services.System.invalidateSystemSettings();
        }
    );

    invalidateCompanySettings = generateCreateEntity(
        'invalidateCacheKey',
        this,
        'invalidatingCache',
        async (companyId) => {
            return services.System.invalidateCompanySettings(companyId);
        }
    );

    invalidateAccountSettings = generateCreateEntity(
        'invalidateCacheKey',
        this,
        'invalidatingCache',
        async (accountId) => {
            return services.System.invalidateAccountSettings(accountId);
        }
    );

    readCacheKey = generateLoadEntity(
        'readCacheKey',
        this,
        'readCache',
        async (key) => {
            return services.System.readCache(key);
        },
        'cacheData'
    );

    readSystemSettings = generateLoadEntity(
        'readCacheKey',
        this,
        'readCache',
        async () => {
            return services.System.readSystemSettings();
        },
        'cacheData'
    );

    getSystemSettings = generateLoadList(
        'systemSettings',
        this,
        'loadingSystemSettings',
        async () => {
            return services.System.settingsService().list();
        },
        'systemSettings'
    );

    // ----- COMPANY SETTINGS

    addSystemSetting = generateCreateEntity(
        'systemSettings',
        this,
        'addingSystemSetting',
        async (data) => {
            data.value = data.value.trim();
            data.name = data.name.trim();
            await services.System.settingsService().create(data);
            this.systemSettings = await services.System.settingsService().list();
        }
    );

    trimSystemSetting(id, value) {
        value = value.trim();
        let s = this.systemSettings.find((x) => x.id === id);
        s.value = value;
    }

    updateSystemSetting(id, value) {
        let s = this.systemSettings.find((x) => x.id === id);
        s.value = value;
    }

    saveSystemSetting = generateUpdateEntity(
        'systemSettings',
        this,
        'savingSystemSettings',
        async (key) => {
            const setting = this.systemSettings.find(
                (setting) => setting.id === key
            );
            console.log('setting', setting);
            const value = setting.value.trim();
            await services.System.settingsService().update(
                { value: value },
                key
            );
        },

    );

    debouncedSaveSystemSetting = debounce(this.saveSystemSetting, 500);

    removeSystemSetting = generateDeleteEntity(
        'companySetting',
        this,
        'removingSystemSettings',
        async (id) => {
            await services.System.settingsService().delete(id);
            this.systemSettings = await services.System.settingsService().list();
        }
    );

    readCompanySettings = generateLoadEntity(
        'readCacheKey',
        this,
        'readCache',
        async (companyId) => {
            return services.System.readCompanySettings(companyId);
        },
        'cacheData'
    );

    readAccountSettings = generateLoadEntity(
        'readCacheKey',
        this,
        'readCache',
        async (accountId) => {
            return services.System.readAccountSettings(accountId);
        },
        'cacheData'
    );

    loadMaterials = generateLoadList(
        'materials',
        this,
        'loadingMaterials',
        async (options) => {
            return services.Materials.list(options);
        },
        'materials'
    );

    loadMaterial = generateLoadEntity(
        'materials',
        this,
        'loadingMaterial',
        async (id) => {
            return services.Materials.fetch(id);
        },
        'editingMaterial'
    );

    createMaterial = generateCreateEntity(
        'materials',
        this,
        'creatingMaterial',
        async (material) => {
            return services.Materials.create({ ...material });
        }
    );

    updateMaterial = generateUpdateEntity(
        'materials',
        this,
        'updatingMaterials',
        async (key, material) => {
            return services.Materials.update(material, material.id);
        }
    );

    get sortedMaterials() {
        return this.reorderingMaterials
            ? orderBy(this.materials, 'order')
            : this.materials;
    }

    updateMaterialOrder(materialId, value) {
        const material =
            this.materials &&
            this.materials.find((x) => x.id === materialId);
        material.order = value;
    }

    saveMaterialOrder = generateUpdateEntity(
        'updatingMaterials',
        this,
        'updatingMaterials',
        async (materialId, order) => {
            await services.Materials.update(
                { order },
                materialId
            );

            return true;
        }
    );

    debouncedSaveMaterialOrder = generatePerKeyDebounce(
        async (materialId, order) => {
            await this.saveMaterialOrder(materialId, order);
        },
        1000
    )


    deleteSubjectLanguage = generateUpdateEntity(
        'subjects',
        this,
        'deletingSubjectLanguage',
        async (subjectId, language) => {
            return services.Subjects.deleteLanguage(subjectId, language);
        }
    );

    updateSubject = generateUpdateEntity(
        'subjects',
        this,
        'updatingSubjects',
        async (key, subject) => {
            return services.Subjects.update(subject, subject.id);
        }
    );

    loadTemplates = generateLoadList(
        'templates',
        this,
        'loadingTemplates',
        async (options) => {
            return services.Templates.all(options);
        },
        'templates'
    );

    loadTemplate = generateLoadEntity(
        'templates',
        this,
        'loadingTemplate',
        async (id) => {
            return services.Templates.superGet(id);
        },
        'editingTemplate'
    );

    loadTemplateSubjects = generateLoadList(
        'template_subjects',
        this,
        'loadingTemplateSubjects',
        async (id) => {
            return services.Templates.superGetSubjects(id);
        },
        'editingTemplateSubjects'
    );

    createTemplate = generateCreateEntity(
        'template',
        this,
        'creatingTemplate',
        async (template) => {
            return services.Templates.create(template);
        }
    );

    loadSubjectTemplates = generateLoadList(
        'subject_templates',
        this,
        'loadingSubjectTemplates',
        async (id) => {
            return services.Subjects.getTemplates(id);
        },
        'editingSubjectTemplates'
    );

    addTemplateSubject = generateCreateEntity(
        'templateSubject',
        this,
        'creatingTemplateSubject',
        async (templateId, subjectId) => {
            return services.Templates.addSubject(templateId, subjectId);
        }
    );

    deleteTemplateSubject = generateDeleteEntity(
        'deleteTemplateSubject',
        this,
        'deletingTemplateSubject',
        async (templateId, subjectId) => {
            return services.Templates.deleteSubject(templateId, subjectId);
        }
    );

    loadCategories = generateLoadList(
        'categories',
        this,
        'loadingCategories',
        async (options) => {
            return services.Categories.all(options);
        },
        'categories'
    );

    loadCategory = generateLoadEntity(
        'categories',
        this,
        'loadingCategory',
        async (id) => {
            return services.Categories.superGet(id);
        },
        'editingCategory'
    );

    loadCategorySubjects = generateLoadList(
        'category_subjects',
        this,
        'loadingCategorySubjects',
        async (id) => {
            return services.Categories.superGetSubjects(id);
        },
        'editingCategorySubjects'
    );

    loadSubjectCategories = generateLoadList(
        'subject_categories',
        this,
        'loadingSubjectCategories',
        async (id) => {
            return services.Subjects.getCategories(id);
        },
        'editingSubjectCategories'
    );

    addCategorySubject = generateCreateEntity(
        'categorySubject',
        this,
        'creatingCategorySubject',
        async (categoryId, subjectId) => {
            return services.Categories.addSubject(categoryId, subjectId);
        }
    );

    deleteCategorySubject = generateDeleteEntity(
        'deleteCategorySubject',
        this,
        'deletingCategorySubject',
        (categoryId, subjectId) => {
            return services.Categories.deleteSubject(categoryId, subjectId);
        }
    );

    get sortedCategories() {
        return this.reorderingCategories
            ? orderBy(this.categories, 'order')
            : this.categories;
    }
    updateCategoryOrder(categoryId, value) {
        const category =
            this.categories &&
            this.categories.find((x) => x.id === categoryId);
        category.order = value;
    }

    saveCategoryOrder = generateUpdateEntity(
        'updatingCategories',
        this,
        'updatingCategories',
        async (categoryId, order) => {
            await services.Categories.update(
                { order },
                categoryId
            );

            return true;
        }
    );

    debouncedSaveCategoryOrder = generatePerKeyDebounce(
        async (categoryId, order) => {
            await this.saveCategoryOrder(categoryId, order);
        },
        1000
    )

    loadSubjects = generateLoadList(
        'subjects',
        this,
        'loadingSubjects',
        async (options) => {
            return services.Subjects.all(options);
        },
        'subjects'
    );

    loadSubject = generateLoadEntity(
        'subjects',
        this,
        'loadingSubject',
        async (id) => {
            return services.Subjects.superGet(id);
        },
        'editingSubject'
    );
    exportSubjects = generateLoadEntity(
        'subjects',
        this,
        'exportingSubject',
        async () => {
            const options = {
                responseType: 'blob',
            };
            let response = await services.Subjects.export(options);
            let fileName = 'subjects.json';

            downloadFile(response.data, fileName);

            return true;
        }
    );

    exportPartners = generateLoadEntity(
        'subjects',
        this,
        'exportingSubject',
        async (params) => {
            const options = {
                responseType: 'blob',
            };
            const response = await services.Partners.export(params, options);

            const contentDisposition =
                response?.headers?.['content-disposition'] || '';

            // Use a fallback filename, in case the header isn't present
            let fileName = 'partners.csv';

            const match = contentDisposition.match(/filename="?([^"]+)"?/);
            if (match && match[1]) {
                fileName = match[1];
            }

            downloadFile(response.data, fileName);

            return true;
        }
    );

    importSubjects = generateLoadEntity(
        'subjectsUpload',
        this,
        'uploadingSubjects',
        async (data) => {
            let result = await services.Subjects.import(data);
            if(result){
                this.commonStore.success(`Subjects imported successfully`);
            }
            return result;
        }
    );

    loadSubjectLanguages = generateLoadEntity(
        'subjectLanguages',
        this,
        'loadingSubjectLanguages',
        async (id) => {
            return services.Subjects.superGetLanguages(id);
        },
        'editingSubjectLanguages'
    );

    editSubjectLanguageContent = generateUpdateEntity(
        'subjectLanguageContent',
        this,
        'updatingSubjectLanguageContent',
        async (subjectId, lang, content) => {
            await services.Subjects.updateSubjectLanguageContent(
                subjectId,
                lang,
                content
            );
            if (!this.editingSubjectLanguages.contents[lang])
                this.editingSubjectLanguages.contents[lang] = {};
            this.editingSubjectLanguages.contents[lang].content = content;
            this.commonStore.success(`language ${lang}  saved successfully`);
        }
    );

    editSubjectLanguageTranslation = generateUpdateEntity(
        'subjectLanguageTranslation',
        this,
        'updatingSubjectLanguageTranslation',
        async (subjectId, lang, title, description, videoJwId, videoThumb) => {
            await services.Subjects.updateSubjectLanguageTranslation(
                subjectId,
                lang,
                title,
                description,
                videoJwId,
                videoThumb
            );
            if (!this.editingSubjectLanguages.languages[lang])
                this.editingSubjectLanguages.languages[lang] = {};
            this.editingSubjectLanguages.languages[lang].title = title;
            this.editingSubjectLanguages.languages[lang].description =
                description;
            this.editingSubjectLanguages.languages[lang].video_id_jw =
                videoJwId;
            this.editingSubjectLanguages.languages[lang].video_thumb =
                videoThumb;
            this.commonStore.success(`language ${lang}  saved successfully`);
        }
    );

    editSubjectLanguageQuestions = generateUpdateEntity(
        'subjectLanguageQuestions',
        this,
        'updatingSubjectLanguageQuestions',
        async (subjectId, lang, question, correct, wrong1, wrong2, wrong3) => {
            await services.Subjects.updateSubjectLanguageQuestions(
                subjectId,
                lang,
                question,
                correct,
                wrong1,
                wrong2,
                wrong3
            );
            if (!this.editingSubjectLanguages.questions[lang])
                this.editingSubjectLanguages.questions[lang] = {};
            this.editingSubjectLanguages.questions[lang].question = question;
            this.editingSubjectLanguages.questions[lang].correct_answer =
                correct;
            this.editingSubjectLanguages.questions[lang].wrong_answer1 = wrong1;
            this.editingSubjectLanguages.questions[lang].wrong_answer2 = wrong2;
            this.editingSubjectLanguages.questions[lang].wrong_answer3 = wrong3;
            this.commonStore.success(`language ${lang}  saved successfully`);
        }
    );

    addSubjectLanguage = generateCreateEntity(
        'subjectLanguage',
        this,
        'creatingSubjectLanguage',
        async (subjectId, lang) => {
            let r = await services.Subjects.createSubjectLanguageTranslation(
                subjectId,
                lang
            );
            console.log(lang, subjectId);
            console.log(r);
            if (!this.editingSubjectLanguages.languages[lang])
                this.editingSubjectLanguages.languages[lang] = {
                    language: lang,
                    subject_id: subjectId,
                };
            this.editingSubjectLanguages.contents[lang] = {};
            this.editingSubjectLanguages.questions[lang] = {};
            this.commonStore.success(`language ${lang}  created successfully`);
        }
    );

    /*editSubjectLanguageContent = generateUpdateEntity( "subjectLanguageContent", this, "updatingSubjectLanguageContent",
    async (subjectId, lang, content)=>{
        return services.Subjects.updateSubjectLanguageContent(subjectId, lang, content)
    }, )*/

    /*editSubjectLanguageTranslation = generateUpdateEntity( "subjectLanguageTranslation", this, "updatingSubjectLanguageTranslation",
    async (subjectId, lang, title, description)=>{
        return services.Subjects.updateSubjectLanguageTranslation(subjectId, lang, title, description)
    }, )*/

    /*editSubjectLanguageQuestion = generateUpdateEntity( "subjectLanguageQuestion", this, "updatingSubjectLanguageQuestion",
    async (subjectId, lang, question, correct, wrong1, wrong2, wrong3)=>{
        return services.Subjects.updateSubjectLanguageQuestion(subjectId, lang, question, correct, wrong1, wrong2, wrong3)
    }, )*/

    removeAclLine = generateDeleteEntity(
        'acl',
        this,
        'removingAclLines',
        (id) => {
            return services.ACL.delete(id);
        }
    );

    createAclLine = generateCreateEntity(
        'acl',
        this,
        'creatingAclLine',
        async (line) => {
            return services.ACL.create(line);
        }
    );

    // how to put here the form??

    loadLanguages = generateLoadList(
        'languages',
        this,
        'loadingLanguages',
        async (options) => {
            return services.Languages.all(options);
        },
        'languages'
    );

    loadLanguage = generateLoadEntity(
        'languages',
        this,
        'loadingLanguage',
        async (languageId) => {
            let editLanguage = await services.Languages.fetch(languageId);
            runInAction(() => {
                this.editingLanguage = editLanguage;
                if (this.languageEditForm)
                    this.languageEditForm.update(
                        this.editingLanguage ? editLanguage : {}
                    );
            });
            return editLanguage;
        },
        'editingLanguage'
    );

    resendNotification = generateUpdateEntity(
        'notificationTargets',
        this,
        'updatingNotificationTargets',
        async (reasonId, id) => {
            return services.Notifications.targetsService(reasonId).resend(id);
        }
    );

    cleanEditingSubjectLanguages() {
        this.editingSubjectLanguages = null;
    }

    cleanEditingSubject() {
        this.editingSubject = null;
    }

    get sortedlanguages() {
        return orderBy(this.languages, 'order');
    }

    get sortedLanguagesDynamic() {
        return this.reorderingLanguages
            ? orderBy(this.languages, 'order')
            : this.languages
    }

    updateLanguageOrder(languageId, value) {
        const language =
            this.languages &&
            this.languages.find((x) => x.id === languageId);
        language.order = value;
    }

    saveLanguageOrder = generateUpdateEntity(
        'updatingLanguages',
        this,
        'updatingLanguages',
        async (languageId, order) => {
            await services.Languages.update(
                { order },
                languageId
            );

            return true;
        }
    );

    debouncedSaveLanguageOrder = generatePerKeyDebounce(
        async (languageId, order) => {
            await this.saveLanguageOrder(languageId, order);
        },
        1000
    )

    get languageResources() {
        if (!this.editingLanguage) return [];
        if (!this.editingLanguage.resources) return [];
        let result = [];

        _each(this.editingLanguage.resources, (value, key) => {
            result.push({ key, value });
        });

        return result;
    }

    get emptyLanguageResources() {
        return this.languageResources.filter((x) => !x.value);
    }

    get emptyLanguageSubjects() {
        return this.languageSubjects.filter((x) => !x.title);
    }

    get languageSubjects() {
        if (!this.editingLanguage) return [];
        if (!this.editingLanguage.subjects) return [];
        return this.editingLanguage.subjects;
    }

    /* Statistics for internal data dashboard */
    loadPartnerSeatsData = generateLoadList(
        'partnerStats',
        this,
        'loadingPartnerStats',
        async () => {
            return services.Stats.allPartnerSeats();
        },
        'partnerStats'
    );

    loadAdmins = generateLoadList(
        'admins',
        this,
        'loadingAdmins',
        async () => {
            return services.Admins.list();
        },
        'admins'
    );

    removeAdmin = generateDeleteEntity(
        'admins',
        this,
        'removingAdmins',
        async (email) => {
            await services.Admins.delete(email);
            runInAction(() => {
                const existing = this.admins.find((x) => x.email === email);
                if (existing) this.admins.remove(existing);
            });
        }
    );

    //	arni@awarego.com	Arni Thor Arnason

    addSuperAdmin = generateCreateEntity(
        'admins',
        this,
        'addingSuperAdmin',
        async (params) => {
            const result = await services.Admins.create(params);
            runInAction(() => {
                this.admins.push(result);
            });
        }
    );

    saveStandards = generateUpdateEntity(
        'templateStandards',
        this,
        'updatingTemplateStandards',
        async (templateId, standards) => {
            return services.Templates.updateStandards(templateId, standards);
        }
    );

    saveTemplate = generateUpdateEntity(
        'updatingTemplate',
        this,
        'updatingTemplate',
        async (templateId, fields) => {
            const result = await services.Templates.update(fields, templateId);
            if (result && this.editingTemplate) {
                Object.assign(this.editingTemplate, result);
            }
            return true;
        }
    );

    get sortedTemplates() {
        return this.reorderingTemplates
            ? orderBy(this.templates, 'order_number')
            : this.templates;
    }
    updateTemplateOrder(templateId, value) {
        const template =
            this.templates &&
            this.templates.find((x) => x.id === templateId);
        template.order_number = value;
    }

    saveTemplateOrder = generateUpdateEntity(
        'updatingTemplates',
        this,
        'updatingTemplates',
        async (templateId, order_number) => {
            await services.Templates.update(
                { order_number },
                templateId
            );

            return true;
        }
    );

    debouncedSaveTemplateOrder = generatePerKeyDebounce(
        async (templateId, order_number) => {
            await this.saveTemplateOrder(templateId, order_number);
        },
        1000
    )

    savePersistent = generateUpdateEntity(
        'templateStandards',
        this,
        'updatingTemplatePersistent',
        async (templateId, persistent) => {
            await services.Templates.updateFlag(templateId, persistent);
            if (this.editingTemplate) {
                if (!this.editingTemplate.definition)
                    this.editingTemplate.definition = {};
                this.editingTemplate.definition.persistent = persistent;
            }
        }
    );

    saveFlag = generateUpdateEntity(
        'templateUpdatingFlag',
        this,
        'updatingTemplateFlag',
        async (templateId, name, value) => {
            await services.Templates.updateFlag(templateId, name, value);
            if (this.editingTemplate) {
                if (!this.editingTemplate.definition)
                    this.editingTemplate.definition = {};
                this.editingTemplate.definition[name] = value;
            }
        }
    );

    saveStandardsForTraining = generateUpdateEntity(
        'trainingStandards',
        this,
        'updatingTrainingStandards',
        async (trainingId, standards) => {
            return services.Automations.updateStandards(trainingId, standards);
        }
    );
}

export default SuperStore;
