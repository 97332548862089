import React from 'react';
import PropTypes from 'prop-types';
import ThemedSpinner from '../themed/ThemedSpinner';
import { observer } from 'mobx-react';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(() => ({
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    th: {
        textAlign: 'left',
        padding: 8,
    },
    td: {
        padding: 8,
        verticalAlign: 'middle',
    },
    actionCell: {
        height: 48,
        textAlign: 'center',
    },
    staticCell: {
        fontSize: 14,
        lineHeight: '32px',
    },
    input: {
        fontSize: 14,
        padding: '6px 8px',
        border: '1px solid #ccc',
        borderRadius: 4,
        outline: 'none',
        lineHeight: 1.4,
        height: 32,
        boxSizing: 'border-box',
    },
    textCenter: {
        textAlign: 'center',
    },
}));

function SettingsTable({
                           removingSettings,
                           removeSetting,
                           settings,
                           onChangeSettingValue,
                           onChangeSettingKey,
                           onBlurSettingValue,
                           additionalSection,
                           keyField = 'name',
                           valueField = 'value',
                           editableKey = false,
                       }) {
    const classes = useStyles();

    if (!settings || settings.length === 0) return null;

    return (
        <table className={classes.table}>
            <thead>
            <tr>
                <th className={classes.th}>Name</th>
                <th className={classes.th}>Value</th>
                <th className={classes.th}></th>
            </tr>
            </thead>
            <tbody>
            {settings.map((s) => (
                <React.Fragment key={s.id}>
                    <tr>
                        {editableKey ? (
                            <td className={classes.td}>
                                <input
                                    size={30}
                                    className={classes.input}
                                    value={s[keyField]}
                                    onChange={(e) =>
                                        onChangeSettingKey(s.id, e.target.value)
                                    }
                                />
                            </td>
                        ) : (
                            <td className={`${classes.td} ${classes.staticCell}`}>
                                {s[keyField]}
                            </td>
                        )}
                        <td className={classes.td}>
                            <input
                                size={100}
                                className={classes.input}
                                value={s[valueField]}
                                onChange={(e) =>
                                    onChangeSettingValue(s.id, e.target.value)
                                }
                                onBlur={(e) =>
                                    onBlurSettingValue(s.id, e.target.value)
                                }
                            />
                        </td>
                        <td className={`${classes.td} ${classes.actionCell}`}>
                            {removingSettings.includes(s.id) ? (
                                <CircularProgress size={24} />
                            ) : (
                                <IconButton
                                    aria-label="delete"
                                    onClick={() => removeSetting(s.id)}
                                    color="secondary"
                                >
                                    <DeleteIcon />
                                </IconButton>
                            )}
                        </td>
                    </tr>
                    {additionalSection && (
                        <tr>
                            <td colSpan={3} className={classes.td}>
                                {additionalSection(s)}
                            </td>
                        </tr>
                    )}
                </React.Fragment>
            ))}
            </tbody>
        </table>
    );
}

SettingsTable.propTypes = {
    removeSetting: PropTypes.func,
};

export default observer(SettingsTable);
