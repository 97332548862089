import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SettingsTable from '../../components/super/settings';
import AddSetting from '../../components/super/add-setting';
import { useStores } from '../../hooks/use-stores';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

function SystemSettings() {
    const { superStore } = useStores();
    const {
        systemSettings,
        loadingSystemSettings,
        addingSystemSetting,
        removingSystemSettings
    } = superStore;

    const loadData = async () => {
        superStore.getSystemSettings();
    };
    useEffect(() => {
        loadData();
    }, []);


    const removeSetting = (settingId) => {
        superStore.removeSystemSetting(settingId);
    };

    const addSetting = (name, value) => {
        const data = {
            name,
            value
        };
        superStore.addSystemSetting(data);
    };


    const onChangeSetting = (id, value) => {
        superStore.updateSystemSetting(id, value);
        superStore.debouncedSaveSystemSetting(id, value);
    };

    const onBlurSettingValue = (id, value) => {
        superStore.trimSystemSetting(id, value);
    }

    return loadingSystemSettings ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Fragment>
                <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        System Settings
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="primary"
                        href={`/companies/settings`}
                    >
                        View available settings
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <AddSetting
                        saveSetting={addSetting}
                        addingSetting={addingSystemSetting}
                    />
                </Grid>
                {systemSettings.length > 0 && (
                    <Grid item xs={10} md={6} style={{maxWidth: '100%', paddingTop: '25px'}}>
                        <SettingsTable
                            settings={systemSettings}
                            removeSetting={removeSetting}
                            removingSettings={removingSystemSettings}
                            onChangeSettingValue={onChangeSetting}
                            onBlurSettingValue={onBlurSettingValue}
                        />
                    </Grid>
                )}
            </Fragment>
        </Grid>
    );
}

export default observer(SystemSettings);
