import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AddRemoveList from '../../components/super/add-remove-list';

function TemplateSubjects() {
    const { superStore } = useStores();
    let { templateId } = useParams();
    const {
        loadingTemplate,
        editingTemplate,
        templatesErrorError,
        loadingSubjects,
        subjects,
        //loadCategorySubjects,
        editingTemplateSubjects,
        addTemplateSubject,
        deleteTemplateSubject,
    } = superStore;

    const loadData = async () => {
        await superStore.loadSubjects();
        await superStore.loadTemplate(templateId);
        await superStore.loadTemplateSubjects(templateId);
    };

    useEffect(() => {
        loadData();
    }, [templateId]);

    /*const mapSubjects = async (subjects) => {
        return await subjects.map((item) => {
            item.name = `[${item.episode_number}] ${item.title}`;
        });
    };*/

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            {loadingTemplate || loadingSubjects || !editingTemplateSubjects ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {templatesErrorError && (
                        <Grid item>
                            <ErrorMessage error={templatesErrorError} />
                        </Grid>
                    )}
                    {editingTemplate && editingTemplateSubjects && (
                        <Fragment>
                            <Grid item xs={12}>
                                <Box mt={4}>
                                    <Typography variant="h2">
                                        Template{`: ${editingTemplate.title}`}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Fragment>
                                <Grid item xs={12}>
                                    <Typography variant="h4" gutterBottom>
                                        Subjects
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <AddRemoveList
                                        addItem={async (subjectId) => {
                                            await addTemplateSubject(
                                                templateId,
                                                subjectId
                                            );
                                            await superStore.loadTemplateSubjects(
                                                templateId
                                            );
                                        }}
                                        removeItem={async (subjectId) => {
                                            await deleteTemplateSubject(
                                                templateId,
                                                subjectId
                                            );
                                            await superStore.loadTemplateSubjects(
                                                templateId
                                            );
                                        }}
                                        itemList={subjects.map((item) => {
                                            item.name = `[${item.episode_number}] ${item.title}`;
                                            return item;
                                        })}
                                        activeItemList={editingTemplateSubjects}
                                        idColumn={'subject_id'}
                                        nameColumn={'name'}
                                    />
                                </Grid>
                            </Fragment>
                        </Fragment>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(TemplateSubjects);
