import React, { useEffect } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import SimpleTable from '../../components/super/SimpleTable';
import { Fab, Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
import { runInAction } from 'mobx';
import NumberInput from '../../components/input/NumberInput';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));
function Materials() {
    const { superStore } = useStores();
    const { loadingMaterials, materials, materialsError, sortedMaterials } = superStore;
    let match = useRouteMatch();
    const classes = useStyles();

    const loadData = async () => {
        superStore.loadMaterials();
    };

    const reorderMaterials = () => {
        runInAction(() => {
            superStore.reorderingMaterials = true;
            superStore.materials = superStore.sortedMaterials;
        });

        setTimeout(() => {
            runInAction(() => {
                superStore.reorderingMaterials = false;
            });
        }, 0);
    }

    const updateOrder = async (materialId, orderNumber) => {
        superStore.updateMaterialOrder(materialId, orderNumber);
        await superStore.debouncedSaveMaterialOrder(
            materialId,
            orderNumber
        )
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Id',
                accessor: 'id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}/edit`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Name',
                accessor: 'title',
                Cell: (x) => (
                    <Link
                        to={`${enforceSlash(match.url)}${
                            x.row.original.id
                        }/edit`}
                    >
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: '# of Languages',
                accessor: (row) => {
                    return row.languages.length;
                },
            },
            {
                Header: 'Order',
                accessor: 'order',
                textAlign: 'center',
                Cell: observer((x) => {
                    let value = superStore.materials.find(
                        (material) => material.id === x.row.original.id
                    ).order;

                    return (
                        <NumberInput
                            value={value}
                            onChange={(orderNumber) =>
                                updateOrder(x.row.original.id, orderNumber)
                            }
                            min={0}
                            step={1}
                        />
                    );
                }),
            },
        ],
        [match.url]
    );

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Materials</Typography>
                </Box>
            </Grid>
            <Tooltip title="Create question" aria-label="add" placement="left">
                <Fab
                    color="primary"
                    href="/content/materials/create"
                    className={classes.fab}
                >
                    <AddIcon />
                </Fab>
            </Tooltip>
            {loadingMaterials ? (
                <ThemedSpinner />
            ) : materials && materials.length > 0 ? (
                <Grid item xs={12}>
                    <SimpleTable
                        columns={columns}
                        data={sortedMaterials}
                        needsFilter={true}
                        extraAction={
                            <Button
                                onClick={reorderMaterials}
                                color="primary"
                                variant="contained"
                            >
                                Reorder
                            </Button>
                        }
                    />
                </Grid>
            ) : (
                <Grid item xs={12}>
                    <Typography>No materials found :(</Typography>
                </Grid>
            )}
            {materialsError && (
                <Grid item>
                    <ErrorMessage error={materialsError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Materials);
