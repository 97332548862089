import React, {Fragment, useEffect, useRef} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { enforceSlash } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import SimpleTable from '../../components/super/SimpleTable';
import {Button} from "@material-ui/core";

function Subjects() {
    const { superStore } = useStores();
    const { loadingSubjects, subjects, subjectsError, uploadingSubjects, subjectsUploadError } = superStore;
    let match = useRouteMatch();


    const loadData = async () => {
        superStore.loadSubjects();
    };

    const exportSubjects = async () => {
        superStore.exportSubjects();
    };

    useEffect(() => {
        loadData();
    }, []);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                accessor: 'title',
                Cell: (x) => (
                    <Link
                        to={`${enforceSlash(match.url)}${
                            x.row.original.subject_id
                        }`}
                    >
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Episode',
                accessor: 'episode_number',
                Cell: (x) => (
                    <Link
                        to={`${enforceSlash(match.url)}${
                            x.row.original.subject_id
                        }`}
                    >
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: 'Id',
                accessor: 'subject_id',
                Cell: (x) => (
                    <Link to={`${enforceSlash(match.url)}${x.value}`}>
                        {x.value}
                    </Link>
                ),
            },
            {
                Header: '# of Rules',
                accessor: 'rulesCount',
            },
            {
                Header: 'Languages',
                accessor: (x) => {
                    return (
                        <Fragment>
                            {x.languages
                                .splice(0, x.languages.length - 1)
                                .map((c, i, arr) => (
                                    <Fragment key={c}>
                                        {/* don't generate a comma after last language */}
                                        {c}
                                        {i !== arr.length - 1 ? ', ' : ''}
                                    </Fragment>
                                ))}
                        </Fragment>
                    );
                },
            },
            {
                Header: 'Public',
                accessor: 'isPublic',
                Cell: (x) =>
                    x.value > 0 ? (
                        <DoneIcon color="primary" />
                    ) : (
                        <ClearIcon color="secondary" />
                    ),
            },
        ],
        []
    );

    const logoFileRef = useRef();

    const handleLogoSelect = async (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        const files = evt.target.files || evt.dataTransfer.files;
        const data = files[0];


        const formData = new FormData();
        formData.append('data', data);

        await superStore.importSubjects(formData);

    };

    const chooseFile = (fileRef) => {
        fileRef.current.click();
    };

    const handleDragOver = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();
        evt.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Subjects</Typography>
                </Box>
            </Grid>
                {(subjectsUploadError) && (
                    <Grid item xs={12}>
                        <ErrorMessage
                            error={subjectsUploadError}
                            errorType="error"
                        />
                    </Grid>
                )}
                <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2"> <Button variant="contained" color={'primary'} onClick={exportSubjects} >Export</Button></Typography>


                    <div className="company-logo-drop-zone">
                        <div
                            className="d-flex flex-center flex-fill flex-column"
                            style={{ height: '220px' }}
                            onDragOver={handleDragOver}
                            onDrop={handleLogoSelect}
                        >
                            <img
                                src="/static/img/drop-icon.svg"
                                alt=""
                            />
                            {uploadingSubjects ? (
                                <div> Uploading...</div>
                            ) : (
                                <>
                                    <div style={{ textAlign: 'center' }}>
                                        <div id="drop_zone">
                                            Drag and drop
                                        </div>
                                        <div className="drop_zone_description">
                                            {' '}
                                            or{' '}
                                            <Button
                                                color={'secondary'}
                                                onClick={(e) => {
                                                    chooseFile(
                                                        logoFileRef,
                                                        e,
                                                    );
                                                }}
                                            >
                                                browse
                                            </Button>{' '}
                                            for a json file
                                        </div>
                                    </div>
                                </>
                            )}
                            <input
                                style={{ display: 'none' }}
                                type="file"
                                multiple={false}
                                accept="application/json"
                                onChange={handleLogoSelect}
                                ref={logoFileRef}
                            />
                        </div>
                    </div>
                </Box>
                </Grid>
            {loadingSubjects ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    {subjects && subjects.length > 0 ? (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={subjects}
                                needsFilter={true}
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <Typography>No videos found :(</Typography>
                        </Grid>
                    )}
                    {subjectsError && (
                        <Grid item>
                            <ErrorMessage error={subjectsError} errorType="error"/>
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(Subjects);
