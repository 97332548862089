import React, { Component } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { rgba } from 'polished';

const BounceAnimation = keyframes`
    0% { transform: translate(0, 0); }
	50% { transform: translate(0, 15px); }
	100% { transform: translate(0, 0); }
`;
const Loading = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${(props) => props.padding || '5px 5px 15px 5px'};
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 100;

    ${(props) =>
        props.fullscreen &&
        css`
            justify-content: center;
        `};
`;
const DotWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    margin: ${(props) => props.margin || '30px 0px 30px 0px'};
    transform: scale(${(props) => props.size / 100});
`;
const Dot = styled.div`
    background-color: ${(props) =>
        rgba(props.color || props.theme.primary, 0.8)};
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    /* Animation */
    animation: ${BounceAnimation} 1s 1s infinite;
    animation-delay: ${(props) => props.delay};
`;

class ThemedSpinner extends Component {
    render() {
        const { fullscreen, color, size, message, padding, margin } = this.props;
        return (
            <Loading fullscreen={fullscreen} padding={padding}>
                <DotWrapper size={size} margin={margin}>
                    <Dot delay="1s" color={color} />
                    <Dot delay="1.2s" color={color} />
                    <Dot delay="1.4s" color={color} />
                </DotWrapper>
                {message}
            </Loading>
        );
    }
}

export default ThemedSpinner;
