import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

function textField({ field, error, Component = 'input', ...rest }) {
    const fieldProp = field.bind();

    console.log('fieldProp', fieldProp);

    return (
        <Fragment>
            <label htmlFor={field.id}>
                {field.label}{' '}
                {field.rules.indexOf('required') > -1 && (
                    <span className="red">*</span>
                )}
            </label>
            <Component
                type="text"
                className="form-control"
                id={field.id}
                {...fieldProp}
                {...rest}
            />
            <div className="error">{field.error || error}</div>
        </Fragment>
    );
}

export default observer(textField);
