import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import ErrorMessage from '../../components/errormessage';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import { useStores } from '../../hooks/use-stores';
import SubjectLanguage from './SubjectLanguage';
import {
    Link,
    useHistory,
    useParams,
    useRouteMatch,
} from 'react-router-dom';
import ACLEditor from './ACLEditor';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@mui/material/Typography';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import { enforceSlash } from '../../utils/helpers';
import { firebaseTabsStylesHook } from '@mui-treasury/styles/tabs';
import TextField from '@material-ui/core/TextField';
import TagsEditor from './TagsEditor';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@mui/material/Avatar';

function Subject() {
    const { superStore, threatAreasStore, commonStore } = useStores();
    const { subjectId, languageId } = useParams();
    const match = useRouteMatch();
    const {
        updatingSubjects,
        editingSubject,
        subjectsError,
        editingSubjectLanguages,
        languages,
        loadingLanguages,
        subjectLanguageError,
        creatingSubjectLanguage,
    } = superStore;

    const [tabIndex, setTabIndex] = useState(0);
    const [newLang, setNewLang] = useState('');

    const tabsStyles = firebaseTabsStylesHook.useTabs();
    const tabItemStyles = firebaseTabsStylesHook.useTabItem();
    const language = languageId;
    let history = useHistory();
    
    const currentLanguage = useMemo(() => {
        return languages.find((x) => x.code === languageId) || null;
    }, [languageId, languages, loadingLanguages, editingSubjectLanguages]);
    const loadData = async () => {
        superStore.cleanEditingSubjectLanguages();
        await superStore.loadLanguages();
        await superStore.loadSubject(subjectId);
        await superStore.loadSubjectLanguages(subjectId);

        await threatAreasStore.loadThreatAreas();
        await superStore.loadCategories();
    };

    const subject =
        editingSubjectLanguages &&
        editingSubjectLanguages.languages &&
        editingSubjectLanguages.languages[language];
    const question =
        editingSubjectLanguages &&
        editingSubjectLanguages.questions &&
        editingSubjectLanguages.questions[language];
    const content =
        editingSubjectLanguages &&
        editingSubjectLanguages.contents &&
        editingSubjectLanguages.contents[language];

    useEffect(() => {
        loadData();
    }, [subjectId]);

    useEffect(() => {
        return () => {
            superStore.cleanEditingSubjectLanguages();
            superStore.cleanEditingSubject();
        }
    }, []);

    const removeLanguage = async () => {
        commonStore.showDeleteConfirm(`${currentLanguage.english_name} language`, languageId, async () => {
            await superStore.deleteSubjectLanguage(subjectId, languageId);
            loadData();
            history.push(`/content/subjects/${subjectId}`);
        });
    };

    const handleSave = async () => {
        let result;
        subjectId
            ? (result = await superStore.updateSubject(
                  superStore.editingSubject.id,
                  {
                      ...superStore.editingSubject,
                  }
              ))
            : (result = await superStore.createSubject(
                  superStore.editingSubject
              ));
        if (result) {
            subjectId
                ? history.push(`/content/subjects/${subjectId}`)
                : history.push('/content/subjects');
        }
    };
    const makeCopy = async () => {
        commonStore.showConfirm(
            `Are you sure you want to make a copy of this subject?`,
            'Make copy',
            'Make subject copy',
            async () => {
                const result = await superStore.makeSubjectCopy(subjectId);
                if (result && result.newId) {
                    history.push(`/content/subjects/${result.newId}`);
                }
            }
        );
    };

    const onAddLanguage = async () => {
        if (newLang !== '') {
            await superStore.addSubjectLanguage(subjectId, newLang);
        }
    };

    useEffect(() => {
        const currentTab =
            editingSubjectLanguages &&
            editingSubjectLanguages.languages &&
            Object.keys(editingSubjectLanguages.languages).indexOf(languageId);
        setTabIndex(currentTab > -1 ? currentTab : 0);
    }, [editingSubjectLanguages]);

    useEffect(() => {
        if (
            editingSubjectLanguages &&
            editingSubjectLanguages.languages &&
            (!Object.keys(editingSubjectLanguages.languages).includes(languageId) || !languageId)
        ){
            history.replace(`/content/subjects/${subjectId}/${Object.keys(editingSubjectLanguages.languages)[0]}`);

        }

    }, [editingSubjectLanguages, languageId, subjectId]);
    

    return !currentLanguage ||
        !editingSubject ||
        !editingSubjectLanguages ||
        !editingSubjectLanguages.languages ? (
        <ThemedSpinner />
    ) : (
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2" gutterBottom>
                        Subject
                        {editingSubject.episode_number
                            ? `: ${editingSubject.episode_number}`
                            : `: ${editingSubject.title}`}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12} justify={'flex-end'}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    onClick={makeCopy}
                >
                    Make Copy
                </Button>
            </Grid>
            <Grid item xs={12}>
                <img
                    src={editingSubject.thumb}
                    style={{ maxWidth: 120, margin: 4 }}
                    alt="thumb"
                />
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                    <strong>Templates</strong>:{' '}
                    {editingSubject.templates.join()}
                    <Button
                        variant="contained"
                        color="primary"
                        href={`${enforceSlash(
                            match.url.split('/').slice(0, -1).join('/')
                        )}templates`}
                        startIcon={<EditIcon />}
                    >
                        Edit Templates
                    </Button>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="body1" gutterBottom>
                    <strong>Categories:</strong>{' '}
                    {editingSubject.categories.join()}
                    <Button
                        variant="contained"
                        color="primary"
                        href={`${enforceSlash(
                            match.url.split('/').slice(0, -1).join('/')
                        )}categories`}
                        startIcon={<EditIcon />}
                    >
                        Edit Categories
                    </Button>
                </Typography>
            </Grid>
            <Grid item xs={10}>
                <TextField
                    type="text"
                    name="episode Number"
                    value={editingSubject.episode_number || ''}
                    onChange={(e) => {
                        editingSubject.episode_number = e.target.value;
                    }}
                    variant="outlined"
                    label="Episode number"
                    fullWidth
                />
            </Grid>
            <Grid item xs={10}>
                <TextField
                    type="text"
                    name="areas"
                    value={
                        (
                            (editingSubject.definition || {}).threatAreas || []
                        ).join(', ') || ''
                    }
                    onChange={(e) => {
                        if (!editingSubject.definition)
                            editingSubject.definition = {};
                        editingSubject.definition.threatAreas = (
                            e.target.value || ''
                        )
                            .split(',')
                            .map((a) => a.trim());
                    }}
                    variant="outlined"
                    label="Threat areas (comma separated)"
                    fullWidth
                />
            </Grid>
            <Grid item xs={10}>
                <TextField
                    type="text"
                    name="tags"
                    value={
                        ((editingSubject.definition || {}).tags || []).join(
                            ', '
                        ) || ''
                    }
                    onChange={(e) => {
                        if (!editingSubject.definition)
                            editingSubject.definition = {};
                        editingSubject.definition.tags = (e.target.value || '')
                            .split(',')
                            .map((a) => a.trim());
                    }}
                    variant="outlined"
                    label="Tags (comma separated)"
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <Box mb={subjectId ? 0 : 4}>
                    {subjectId && updatingSubjects.includes(subjectId) ? (
                        <ThemedSpinner />
                    ) : (
                        <Button
                            onClick={handleSave}
                            color="primary"
                            variant="contained"
                        >
                            Save
                        </Button>
                    )}
                </Box>
            </Grid>
            <TagsEditor editingItem={editingSubject} targetType={'question'} />
            <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                    Available languages
                </Typography>
                <Select
                    value={newLang}
                    onChange={(e) => {
                        setNewLang(e.target.value);
                    }}
                >
                    {languages
                        .filter((x) => !editingSubjectLanguages.languages[x])
                        .map((lang) => (
                            <MenuItem key={lang.code} value={lang.code}>
                                {lang.english_name}
                            </MenuItem>
                        ))}
                </Select>
                <Button
                    color={'primary'}
                    size={'small'}
                    variant={'contained'}
                    disabled={creatingSubjectLanguage}
                    onClick={onAddLanguage}
                    startIcon={creatingSubjectLanguage && <CircularProgress />}
                >
                    {' '}
                    Add{' '}
                </Button>
            </Grid>
            <Grid item xs={10}>
                <ErrorMessage
                    errorType={'error'}
                    error={subjectLanguageError}
                ></ErrorMessage>
            </Grid>
            <Grid item xs={10}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} gridGap={10}>
                    <Avatar src={currentLanguage.flag_image} sx={{ width: 32, height: 32 }} />
                    <Typography variant="h6" sx={{textAlign: 'left'}}>
                        {currentLanguage.english_name}
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={10}>
                <AppBar position={'static'}>
                    <Tabs
                        classes={tabsStyles}
                        value={tabIndex}
                        onChange={(e, index) => setTabIndex(index)}
                        variant="scrollable"
                        scrollButtons="auto"
                    >
                        {Object.keys(editingSubjectLanguages.languages).map(
                            (language) => (
                                <Tab
                                    key={language}
                                    classes={tabItemStyles}
                                    label={language}
                                    component={Link}
                                    to={`/content/subjects/${subjectId}/${language}`}
                                />
                            )
                        )}
                    </Tabs>
                </AppBar>
            </Grid>

            <Grid item xs={10}>
                <SubjectLanguage
                    subject={subject}
                    question={question}
                    content={content}
                    language={language}
                    onRemove={removeLanguage}
                    editingSubjectLanguages={editingSubjectLanguages}
                />
            </Grid>
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h4" gutterBottom>
                        Access control
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <ACLEditor
                    acl={editingSubject.acl}
                    unique_id={editingSubject.unique_id}
                />
            </Grid>
            {subjectsError && (
                <Grid item xs={12}>
                    <ErrorMessage error={subjectsError} />
                </Grid>
            )}
        </Grid>
    );
}

export default observer(Subject);
