import React, { Fragment, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { formatDate, formatNumber } from '../../utils/helpers';
import { useStores } from '../../hooks/use-stores';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import SimpleTable from '../../components/super/SimpleTable';
import { FormControlLabel, Typography } from '@material-ui/core';
import { Info } from '@material-ui/icons';
import { Grid } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';

import { makeStyles } from '@material-ui/core/styles';
import GreenCheckbox from '../../components/GreenCheckbox';
import Button from '@material-ui/core/Button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import MrrChart from '../../components/MrrChart';
import { Popover } from '@mui/material';
import PartnerInfoWithPopover from '../../components/super/partnerInfoWithPopover';

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
    },
}));

function PartnersList() {
    const { superStore } = useStores();
    const { loadingPartners, partners, metricDates } = superStore;
    const classes = useStyles();
    const [activeOnly, setActiveOnly] = React.useState(true);
    const [withCompanies, setWithCompanies] = React.useState(false);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const [selectedDateError, setSelectedDateError] = React.useState(null);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        superStore.loadPartners()
    };

    const handleChangeActiveOnly = (event) => {
        setActiveOnly(event.target.checked);
    };

    const handleChangeWithCompanies = (event) => {
        setWithCompanies(event.target.checked);
    };

    const columns = React.useMemo(
        () => [
            {
                Header: 'Name',
                Cell: (x) => (
                    <Link to={`/partners/${x.row.original.id}`}>{x.value}</Link>
                ),
                accessor: 'name',
            },
            {
                Header: 'Created',
                accessor: 'created',
                Cell: (x) => formatDate(x.value),
            },
            {
                Header: 'MRR History',
                accessor: 'mrrMetrics',
                id: 'mrrChart',
                key: 'mrrChart',
                Cell: (x) => {
                    return (
                        <Box width={200} height={50} border={'1px solid green'}>
                            <MrrChart
                                partnerId={x.row.original.id}
                                metrics={x.value}
                            />
                        </Box>
                    );
                },
            },
            {
                Header: 'MRR',
                accessor: (x) => {
                    return (
                        (x.mrrMetrics &&
                            x.mrrMetrics.length > 0 &&
                            x.mrrMetrics[0].mrr) ||
                        0
                    );
                },
                id: 'mrr',
                key: 'mrr',
                Cell: (x) => {
                    return (
                        formatNumber(x.value, 0) + ' ' + x.row.original.currency
                    );
                },
            },
            {
                Header: 'Billing',
                accessor: 'customBilling',
                Cell: (x) =>
                    x.value ? (
                        'Custom'
                    ) : (
                        <>
                            <a
                                href={x.row.original.customerLink}
                                target="_blank"
                            >
                                Chargebee
                            </a>
                            {x.row.original.warnSubscriptions && (
                                <WarningIcon color={'error'} />
                            )}
                        </>
                    ),
            },
            {
                Header: 'Subscriptions',
                accessor: 'subscriptions',
                Cell: (x) => {
                    const sComplete =
                        x.row.original.subscriptions &&
                        x.row.original.subscriptions.find(
                            (x) => x.plan === 'Complete'
                        );
                    const sTraining =
                        x.row.original.subscriptions &&
                        x.row.original.subscriptions.find(
                            (x) => x.plan === 'Training'
                        );
                    const sAssessment =
                        x.row.original.subscriptions &&
                        x.row.original.subscriptions.find(
                            (x) => x.plan === 'Assessment'
                        );

                    return x.row.original.customBilling ? (
                        ''
                    ) : (
                        <Box display={'flex'} flexDirection={'column'} gap={3}>
                            <>
                                {sComplete && (
                                    <Box
                                        whiteSpace={'nowrap'}
                                        lineHeight={'30px'}
                                    >
                                        <Box
                                            display="inline-block"
                                            whiteSpace={'nowrap'}
                                            width={10}
                                            height={10}
                                            borderRadius={5}
                                            bgcolor={
                                                x.row.original.warnComplete
                                                    ? 'red'
                                                    : 'green'
                                            }
                                            border="1px solid  #444444"
                                        />{' '}
                                        <a
                                            href={sComplete.subscriptionLink}
                                            target="_blank"
                                        >
                                            Complete
                                        </a>
                                        {sComplete.due_invoices_count ? (
                                            <>
                                                <Tooltip
                                                    title="Due invoices"
                                                    aria-label="add"
                                                    placement="left"
                                                >
                                                    <Box display="inline">
                                                        ⏳
                                                        {
                                                            sComplete.due_invoices_count
                                                        }
                                                    </Box>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            ``
                                        )}
                                        , 💸{sComplete.nextBilling}
                                    </Box>
                                )}
                                {sTraining && (
                                    <Box
                                        whiteSpace={'nowrap'}
                                        lineHeight={'30px'}
                                    >
                                        <Box
                                            display="inline-block"
                                            whiteSpace={'nowrap'}
                                            width={10}
                                            height={10}
                                            borderRadius={5}
                                            bgcolor={
                                                x.row.original.warnTraining
                                                    ? 'red'
                                                    : 'green'
                                            }
                                            border="1px solid #444444"
                                        />{' '}
                                        <a
                                            href={sTraining.subscriptionLink}
                                            target="_blank"
                                        >
                                            Training
                                        </a>
                                        {sTraining.due_invoices_count ? (
                                            <>
                                                <Tooltip
                                                    title="Due invoices"
                                                    aria-label="add"
                                                    placement="left"
                                                >
                                                    <Box display="inline">
                                                        ⏳
                                                        {
                                                            sTraining.due_invoices_count
                                                        }
                                                    </Box>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            ``
                                        )}
                                        , 💸{sTraining.nextBilling}
                                    </Box>
                                )}
                                {sAssessment && (
                                    <Box
                                        whiteSpace={'nowrap'}
                                        lineHeight={'30px'}
                                    >
                                        <Box
                                            display="inline-block"
                                            whiteSpace={'nowrap'}
                                            width={10}
                                            height={10}
                                            borderRadius={5}
                                            bgcolor={
                                                x.row.original.warnAssessment
                                                    ? 'red'
                                                    : 'green'
                                            }
                                            border="1px solid #444444"
                                        />{' '}
                                        <a
                                            href={sAssessment.subscriptionLink}
                                            target="_blank"
                                        >
                                            Assessment
                                        </a>
                                        {sAssessment.due_invoices_count ? (
                                            <>
                                                <Tooltip
                                                    title="Due invoices"
                                                    aria-label="add"
                                                    placement="left"
                                                >
                                                    <Box display="inline">
                                                        ⏳{' '}
                                                        {
                                                            sAssessment.due_invoices_count
                                                        }
                                                    </Box>
                                                </Tooltip>
                                            </>
                                        ) : (
                                            ``
                                        )}
                                        , 💸{sAssessment.nextBilling}
                                    </Box>
                                )}
                            </>
                        </Box>
                    );
                },
            },
            {
                Header: 'Companies',
                accessor: 'company_count',
            },
            {
                Header: 'Companies Active ',
                accessor: 'companiesActive',
            },
            {
                Header: 'Seats',
                accessor: 'seatsActive',
            },
            {
                Header: 'Users',
                accessor: 'usersActive',
            },
            {
                Header: 'Info',
                accessor: 'lms_domain',
                Cell: (x) => {
                    return (
                        <PartnerInfoWithPopover
                            partnerId={x.row.original.id}
                            partnerInfo={x.row.original}
                        />
                    );
                },
            },
        ],
        []
    );

    const handleExport = () => {
        if (!selectedDate) {
            return setSelectedDateError('Please select a date');
        }
        setSelectedDateError(null);
        superStore.exportPartners({
            activeOnly,
            selectedDate: selectedDate.format('YYYY-MM-DD'),
            withCompanies,
        });
    };

    return (
        <Grid
            container
            direction="row"
            justify="center"
            align="center"
            spacing={2}
        >
            <Grid item xs={12}>
                <Box mt={4}>
                    <Typography variant="h2">Partners</Typography>
                </Box>
            </Grid>
            {loadingPartners ? (
                <ThemedSpinner />
            ) : (
                <Fragment>
                    <Tooltip
                        title="Create partner"
                        aria-label="add"
                        placement="left"
                    >
                        <Fab
                            color="primary"
                            href="/partners/create"
                            className={classes.fab}
                        >
                            <AddIcon />
                        </Fab>
                    </Tooltip>

                    {partners && partners.length > 0 && (
                        <Grid item xs={12}>
                            <SimpleTable
                                columns={columns}
                                data={partners.filter((p) => {
                                    return activeOnly ? p.enabled === 1 : true;
                                })}
                                needsFilter={true}
                                pageSize={100}
                                maxWith={2000}
                                extraFilter={
                                    <>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    checked={activeOnly}
                                                    onChange={
                                                        handleChangeActiveOnly
                                                    }
                                                    name="checkedG"
                                                />
                                            }
                                            label="Active only"
                                        />
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                        >
                                            <LocalizationProvider
                                                dateAdapter={AdapterMoment}
                                            >
                                                <DatePicker
                                                    value={selectedDate}
                                                    onChange={(newValue) => {
                                                        setSelectedDate(
                                                            newValue
                                                        );
                                                    }}
                                                    shouldDisableDate={(
                                                        day
                                                    ) => {
                                                        const candidate = day.format('YYYY-MM-DD');
                                                        return !metricDates.includes(candidate);
                                                    }}
                                                    helperText={
                                                        selectedDateError
                                                    }
                                                    error={!!selectedDateError}
                                                />
                                            </LocalizationProvider>
                                            {selectedDateError && (
                                                <Typography
                                                    color={'error'}
                                                    variant={'caption'}
                                                >
                                                    {selectedDateError}
                                                </Typography>
                                            )}
                                        </Box>
                                        <Button
                                            variant={'contained'}
                                            color={'primary'}
                                            onClick={handleExport}
                                        >
                                            Export
                                        </Button>
                                        <FormControlLabel
                                            control={
                                                <GreenCheckbox
                                                    checked={withCompanies}
                                                    onChange={
                                                        handleChangeWithCompanies
                                                    }
                                                    name="checkedG"
                                                />
                                            }
                                            label="With Companies"
                                        />
                                    </>
                                }
                            />
                        </Grid>
                    )}
                </Fragment>
            )}
        </Grid>
    );
}

export default observer(PartnersList);
