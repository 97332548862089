import React, { Fragment } from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import ThemedSpinner from '../../components/themed/ThemedSpinner';
import MaUTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import IconButton from '@material-ui/core/IconButton';
import LastPageIcon from '@material-ui/icons/LastPage';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

/**
 * @param {object[]} columns - React Table column definitions
 * @param {object[]} data - The actual row data array
 * @param {function} fetchData - Called when pageIndex/pageSize/filters change
 * @param {boolean} loading - Show spinner if true
 * @param {number} pageCount: controlledPageCount - The total number of pages
 * @param {function} [getRowStyle] - (Optional) a function(row) => style object
 */
function Table({
                   columns,
                   data,
                   fetchData,
                   loading,
                   pageCount: controlledPageCount,
                   getRowStyle,
               }) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, filters },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 50 },
            manualPagination: true,
            manualFilters: true,
            pageCount: controlledPageCount,
        },
        useFilters,
        usePagination
    );

    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, filters });
    }, [fetchData, pageIndex, pageSize, filters]);

    return (
        <MaUTable {...getTableProps()}>
            <TableHead>
                {headerGroups.map((headerGroup, i) => (
                    <TableRow key={i} {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, j) => (
                            <TableCell key={j} {...column.getHeaderProps()}>
                                {column.Header !== ' ' ? column.render('Header') : null}
                                {column.Filter && <div>{column.render('Filter')}</div>}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>

            <TableBody {...getTableBodyProps()}>
                {loading ? (
                    <TableRow>
                        <TableCell colSpan="10000">
                            <ThemedSpinner />
                        </TableCell>
                    </TableRow>
                ) : (
                    <Fragment>
                        {page.map((row, i) => {
                            prepareRow(row);

                            const rowStyle = getRowStyle ? getRowStyle(row) : {};

                            return (
                                <TableRow
                                    key={i}
                                    {...row.getRowProps({
                                        style: rowStyle,
                                    })}
                                >
                                    {row.cells.map((cell, j) => (
                                        <TableCell key={j} {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            );
                        })}
                    </Fragment>
                )}
            </TableBody>

            <TableFooter>
                <TableRow>
                    <TablePagination
                        rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
                        count={controlledPageCount * pageSize}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        onChangePage={(e, newPage) => {
                            gotoPage(newPage);
                        }}
                        onChangeRowsPerPage={(e) => {
                            setPageSize(Number(+e.target.value));
                        }}
                        ActionsComponent={() => (
                            <Fragment>
                                <IconButton
                                    onClick={() => gotoPage(0)}
                                    disabled={!canPreviousPage}
                                    aria-label="first page"
                                >
                                    <FirstPageIcon />
                                </IconButton>
                                <IconButton
                                    onClick={() => previousPage()}
                                    disabled={!canPreviousPage}
                                    aria-label="previous page"
                                >
                                    <KeyboardArrowLeft />
                                </IconButton>
                                <IconButton
                                    onClick={() => nextPage()}
                                    disabled={!canNextPage}
                                    aria-label="next page"
                                >
                                    <KeyboardArrowRight />
                                </IconButton>
                                <IconButton
                                    onClick={() => gotoPage(pageCount - 1)}
                                    disabled={!canNextPage}
                                    aria-label="last page"
                                >
                                    <LastPageIcon />
                                </IconButton>
                            </Fragment>
                        )}
                    />
                </TableRow>
            </TableFooter>
        </MaUTable>
    );
}

export default Table;
